import { Vector3 } from 'three';
import { Content, ContentProps } from '../base';

export interface StaticContentProps extends ContentProps {
  position: Vector3;
  scale: Vector3;
  rotation: Vector3;
  isRelatedToAnimation?: true;
}

/**
 * Static content. I.e. a part of the background.
 * The transform matrix will not update (!)
 */
export class StaticContent extends Content {
  public position: Vector3;

  public scale: Vector3;

  public rotation: Vector3;

  public isRelatedToAnimation: true;

  constructor(props: StaticContentProps) {
    super(props);
    this.type = 'static';
    const { position, scale, rotation, isRelatedToAnimation } = props;
    this.position = position;
    this.scale = scale;
    this.rotation = rotation;
    this.isRelatedToAnimation = isRelatedToAnimation;
  }
}
