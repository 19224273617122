import ViewerOverlordBase from '../../base';

export default function onMouseMove(
  this: ViewerOverlordBase,
  event: MouseEvent,
) {
  const rect = this.renderer.domElement.getBoundingClientRect();
  this.mouse.x =
    ((event.clientX - rect.left) / (rect.right - rect.left)) * 2 - 1;
  this.mouse.y =
    -((event.clientY - rect.top) / (rect.bottom - rect.top)) * 2 + 1;
}
