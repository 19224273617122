import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { PATHS } from '../../../../../config';
import ViewerOverlordBase from '../../../base';
/**
 *
 * @param this
 */
export default function initDracoLoader(this: ViewerOverlordBase) {
  this.dracoLoader = new DRACOLoader(this.loadingManager);
  this.dracoLoader.setDecoderPath(PATHS.DRACO);
}
