/* eslint-disable import/prefer-default-export */
import { Object3D } from 'three';

type Callback = (object: Object3D) => void;

let children: Object3D[] = /* @__PURE__ */ [];
const defaultCallback = (child: Object3D): void => {
  children.push(child);
};
/**
 *
 * @param object
 * @returns
 */
const hasChildren = (object: Object3D): boolean => {
  if (object.children === undefined) return false;
  return object.children.length !== 0;
};
/**
 * Recursive function
 * @param object `THREE.Object3D`
 * @param callback Callback function
 * @returns void
 */
const getChildren = (object: Object3D, callback: Callback): void => {
  if (object.children === undefined) return;
  // eslint-disable-next-line
  object.children.forEach((child: Object3D, index: number) => {
    callback(child);
    if (hasChildren(child)) getChildren(child, callback);
  });
};
/**
 * Get all children of a `THREE.Object3D`.
 * May return `Meshes`, `Lights`, `Groups` etc.
 * @param object
 * @param callback
 * @returns
 */
const getAllChildren = (
  object: Object3D,
  callback: Callback = defaultCallback,
): Object3D[] => {
  children = [];
  getChildren(object, callback);
  return children;
};

export { getAllChildren };
