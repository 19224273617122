import ViewerOverlordBase from '../../base';
import Skybox from '../../base/skybox';

/**
 * Init skybox.
 *
 * N.B. Will probably conflict with `initSceneBackground`.
 * @deprecated WIP
 */
export default function initSkybox(this: ViewerOverlordBase) {
  this.skybox = new Skybox();
}
