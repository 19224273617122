export const MODEL: string =
  '/static/models/flen_fotogrammetri_gltf/scene_mesh_textured_sub.glb';
export const MODEL_OBJ: string =
  '/static/models/flen_fotogrammetri_gltf/scene_mesh_textured.obj';
export const MODEL_MTL: string =
  '/static/models/flen_fotogrammetri_gltf/scene_mesh_textured.mtl';
export const BACKGROUND: string =
  '/static/models/flen_fotogrammetri_gltf/buildings.glb';
export const GROUND: string =
  '/static/models/flen_fotogrammetri_gltf/ground_with_elevation.glb';
export const SKYBOX: string = '/static/textures/skybox2/';
export const DRACO: string = '/static/libs/draco/gltf/';

export const ANIMATION_PATH: string = '/static/animations/zombie/';
export const ANIMATION_BASE_MODEL: string = '_zombie.fbx';
export const ANIMATION_WALK: string = 'walk.fbx';
export const ANIMATION_RUN: string = 'run.fbx';
export const ANIMATION_IDLE: string = 'idle.fbx';
export const ANIMATION_DANCE: string = 'dance.fbx';

export const TILES: string = '/static/tiles/664118/tileset.json';

export const TILES_POINTCLOUD: string =
  '/static/tiles/GIT_IGNORE/flen_punktmoln/tileset.json';

export const ANIMATION_TEST: string = '/static/test_files/animtest.glb';
export const ANIMATION_TEST2: string =
  'https://storage.googleapis.com/omnitwin-voss-dev.appspot.com/models/vossloh_flen10_photogram_and_anims.glb';

export const ANIMATION_TEST3 =
  '/static/GIT_IGNORE/vossloh_flen10_photogram_and_anims.glb';

export const ANIMATION_TEST4 =
  '/static/GIT_IGNORE/vossloh_flen10_photogram_and_anims_adjustment.glb';

export const ANIMATION_TEST5 =
  '/static/models/flen_fotogrammetri_gltf/vossloh_flen14_onlytrain.glb';

export const ANIMATION_BG =
  '/static/models/flen_fotogrammetri_gltf/vossloh_flen14_onlybackdrop.glb';

export * as ENVIRONMENT from './environment/index';
