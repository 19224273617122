import { getAllChildren } from '../../../../utils/get_children';
import ViewerOverlordBase from '../../base';

export default function onMouseDown(
  this: ViewerOverlordBase,
  event: MouseEvent,
) {
  if (event.button !== 0) return;
  this.raycaster.setFromCamera(this.mouse, this.camera);
  const intersects = this.raycaster.intersectObjects(this.scene.children, true);
  this.hoveredObject = undefined;
  if (intersects.length > 0) {
    const intersect = intersects[0];
    const { parent } = intersect.object.userData;
    if (parent === undefined) return;
    this.hoveredObject = parent;
    this.selectedOutlineEffect.selection.set(getAllChildren(parent));
  } else {
    return;
  }

  if (this.hoveredObject === undefined) return;
  this.contentToFollow = this.hoveredObject;
  this.setSelectedObject(this.contentToFollow.name);
  this.mixer.timeScale = 0;
  this.setPlaybackSpeedEvent(0); // Sync with React!
}
