/* eslint-disable */
import { AnimationAction, LoopOnce } from 'three';
import getSequenceNumber from '../../../../../utils/get_sequence_number';
import LoggerMcLog from '../../../../../utils/logger_mc_log';
import ViewerOverlordBase from '../../../base';
/**
 * @deprecated use `all animation`
 */
export default function updateGroupedGUI(this: ViewerOverlordBase) {
  const actionDictionary: { [key: string]: AnimationAction[] } = {}; // Helper
  this.clips.forEach((clip, clipIndex) => {
    if (+getSequenceNumber(clip.name) === NaN) return; // Some sequences are not a number
    const reference = actionDictionary[getSequenceNumber(clip.name)]; // Check if sequence exist
    if (reference === undefined) {
      actionDictionary[getSequenceNumber(clip.name)] = [];
    }
    const sequence: AnimationAction[] =
      actionDictionary[getSequenceNumber(clip.name)];
    const action: AnimationAction = this.mixer.clipAction(clip);
    action.loop = LoopOnce;
    action.clampWhenFinished = true; // Don't reset
    action.zeroSlopeAtEnd = false;
    action.zeroSlopeAtStart = false;
    action.setEffectiveTimeScale(1); // Dunno what this does...
    sequence.push(action);
  });
  const { sequenceStates } = this.debugState; // Actual values used by `lil-gui`
  let numberOfActionsInSequence = 0;
  const actionStateValues = actionDictionary;
  Object.entries(actionDictionary).forEach(([k, v]) => {
    sequenceStates[k] = () => {
      numberOfActionsInSequence = 0;
      const prevActions = actionStateValues[+k - 1] || [];
      console.log('--- Stopping Sequence:', +k - 1, '---');
      prevActions.forEach((a) => {
        console.log('stop :>> ', a); // Stop previous action
        a.stop();
      });
      console.log('--- Playing Sequence:', +k, '---');
      v.forEach((a) => {
        numberOfActionsInSequence++;
        console.log('play :>> ', a);
        a.stop(); // Reset current action
        a.play();
      });
    };
  });
  /**
   * Callback for `finished` event.
   * @param e
   * @returns
   */
  const sequenceDone = (e) => {
    // LoggerMcLog.notImporant(`done :>> ${e.action._clip.name}`);
    numberOfActionsInSequence--;
    if (numberOfActionsInSequence !== 0) return;
    console.log('--- All done ---');
  };
  this.mixer.addEventListener('finished', sequenceDone); // properties of e: type, action and direction
  Object.keys(actionDictionary).forEach((k) => {
    this.animDictFolder.add(sequenceStates, k);
  });
}
