import { Vector3 } from 'three';
import { PATHS, POSITIONS } from '..';
import { MESH_NAMES, PARENT_MESH_NAMES } from '../ids/glb';
import { AnimationContent, AnimationContentProps } from './animation';
import { StaticContent, StaticContentProps } from './static';

/**
 * An `overlord` that holds both `static` and `animation` content.
 * These are loaded differently. Mainly, the `static` content do not update its transformation matrix.
 * Also, shadows can be disabled with static content.
 */
export class ContentOverlord {
  // Part of the background
  public staticContent: { [key: string]: StaticContent };

  // Part of the animation controller
  public animationContent: { [key: string]: AnimationContent };

  /**
   * Init empty objects.
   */
  constructor() {
    this.staticContent = {};
    this.animationContent = {};
  }

  /**
   * Add a `static` content. Assumes props are correct.
   * @param props Static Content props
   */
  addStaticContent(props: StaticContentProps) {
    const staticContent = new StaticContent(props);
    this.staticContent[props.id] = staticContent;
  }

  /**
   * Add a `dynamic` content. Assumes props are correct.
   * @param props Static Content props
   */
  addAnimationContent(props: AnimationContentProps) {
    const animationContent = new AnimationContent(props);
    this.animationContent[props.id] = animationContent;
  }
}
// eslint-disable-next-line
const contentOverlord = new ContentOverlord();
// eslint-disable-next-line
const blenderGISPositin2 = [-70, POSITIONS.ZERO_HEIGHT_POSITION, 15]; // WIP
// eslint-disable-next-line
const blenderGISPositin = new Vector3(
  blenderGISPositin2[0],
  blenderGISPositin2[1],
  blenderGISPositin2[2],
); // WIP
// eslint-disable-next-line
const rotation = new Vector3(); // WIP
// eslint-disable-next-line
const scale = new Vector3(1, 1, 1); // WIP

// Ground should be offset slightly above the `map`
contentOverlord.addStaticContent({
  id: 'ground-plane', // Unique ID, used for lookup
  name: 'Satellitkarta', // Name in debug panel
  position: new Vector3(
    blenderGISPositin2[0],
    blenderGISPositin2[1] + 0.1,
    blenderGISPositin2[2],
  ), // Position of parent
  rotation, // WIP
  scale, // Rotation of parent
  comment: 'Ground with static satellite image. Created from Blender GIS', // For debugging only (!)
  path: PATHS.GROUND, // Either locally or on the `Storage`
  castShadow: false,
  receiveShadow: true,
  reflectivity: 0,
});

// Buildings are related to the ground
contentOverlord.addStaticContent({
  id: 'buildings',
  name: 'Byggnader',
  position: blenderGISPositin,
  rotation,
  scale,
  comment:
    'Low poly buildings, based on  OpenStreetMap. Created from Blender GIS.',
  path: PATHS.BACKGROUND,
  castShadow: false,
  receiveShadow: false,
});

const zero = new Vector3();
// Buildings are related to the ground
contentOverlord.addStaticContent({
  id: 'photogrammetry',
  name: 'Fotogrammetrimodell',
  position: POSITIONS.ANIMATION,
  rotation: zero,
  scale: new Vector3(2, 2, 2),
  comment: 'Photogrammetry model based on animation',
  path: PATHS.ANIMATION_BG,
  castShadow: false,
  receiveShadow: true,
  isRelatedToAnimation: false,
});

// // The real-world position is not set. Uses an arbitrary offset
// contentOverlord.addStaticContent({
//   id: 'photogrammetry',
//   name: 'Photogrammetry model',
//   position: new Vector3(70, 55, -250),
//   rotation,
//   scale: new Vector3(2, 1, 2),
//   comment: 'Decimated photogrammetry model (very low-res)',
//   path: PATHS.MODEL,
//   castShadow: false,
//   receiveShadow: false,
// });

// First animation test. Should hold many animation clips atm.
contentOverlord.addAnimationContent({
  id: 'animtest',
  name: 'Animation Test 1#',
  comment: 'First animation test',
  contentToFollowIndex: 5, // train
  selectableObjects: [
    {
      id: '1Loket',
      name: 'Loket',
      index: 5,
    },
    { id: '7kirow_boogie', name: 'Kirowkran', index: 4 },
  ],
  visibility: {
    pre: [
      MESH_NAMES.CUBE,
      PARENT_MESH_NAMES.TESTLOOK,
      PARENT_MESH_NAMES.LOKET,
      PARENT_MESH_NAMES.RALSVAGN_RAM,
      PARENT_MESH_NAMES.PLATTVAGN_LONG2,
      PARENT_MESH_NAMES.PLATTVAGN_SHORT,
      PARENT_MESH_NAMES.PLATTVAGN_LONG,
      PARENT_MESH_NAMES.RALSVAGN_RAM2,
      PARENT_MESH_NAMES.KIROW_BOOGIE,
      PARENT_MESH_NAMES.END1_NEW,
      PARENT_MESH_NAMES.END2_NEW,
      PARENT_MESH_NAMES.MID1_NEW,
      PARENT_MESH_NAMES.MID2_NEW,
    ],
    post: [
      MESH_NAMES.CUBE,
      PARENT_MESH_NAMES.TESTLOOK,
      PARENT_MESH_NAMES.LOKET,
      PARENT_MESH_NAMES.RALSVAGN_RAM,
      PARENT_MESH_NAMES.PLATTVAGN_LONG2,
      PARENT_MESH_NAMES.PLATTVAGN_SHORT,
      PARENT_MESH_NAMES.PLATTVAGN_LONG,
      PARENT_MESH_NAMES.RALSVAGN_RAM2,
      PARENT_MESH_NAMES.KIROW_BOOGIE,
      PARENT_MESH_NAMES.END1_NEW,
      PARENT_MESH_NAMES.END2_NEW,
      PARENT_MESH_NAMES.MID1_NEW,
      PARENT_MESH_NAMES.MID2_NEW,
    ],
  },
  animations: [
    // Part A
    {
      name: 'arrival',
      comment:
        'Whole train arrives at station. Enters from east and arrives at track 2',
      index: 1,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        PARENT_MESH_NAMES.END1_NEW,
        PARENT_MESH_NAMES.MID1_NEW,
        PARENT_MESH_NAMES.MID2_NEW,
        PARENT_MESH_NAMES.MID2_NEW,
        PARENT_MESH_NAMES.END2_NEW,
      ],
      restrictions: {
        locomotive: {
          moveForward: true,
          moveBackward: false,
          reasonBackward: 'Tåget måste åka framåt.',
        },
        kirow: {
          moveForward: false,
          moveBackward: false,
          reasonForward:
            'Loket har ej anlänt till perrongen. Byt till loket för att fortsätta.',
          reasonBackward:
            'Loket har ej anlänt till perrongen. Byt till loket för att fortsätta.',
        },
      },
    },
    // Part B
    {
      name: 'transition',
      comment: 'Kirow exists train. Transition from track 2 to track 1',
      index: 2,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        PARENT_MESH_NAMES.END1_NEW,
        PARENT_MESH_NAMES.MID1_NEW,
        PARENT_MESH_NAMES.MID2_NEW,
        PARENT_MESH_NAMES.END2_NEW,
      ],
      restrictions: {
        locomotive: {
          moveForward: false,
          moveBackward: false,
          reasonForward:
            'Spårbitarna är ej bytta. Byt till Kirow-kranen för att fortsätta.',
          reasonBackward:
            'Spårbitarna är ej bytta. Byt till Kirow-kranen för att fortsätta.',
        },
        kirow: {
          moveForward: true,
          moveBackward: false,
          reasonBackward:
            'Kirow-kranen sitter fast i tåget. Det går bara att åka frammåt',
        },
      },
    },
    // Part C
    {
      name: 'kirow-move-end-1-org',
      comment:
        'Kirow extends arms. Moves "End1_org" at track 2 (furthest to the east)',
      index: 3,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        PARENT_MESH_NAMES.END1_NEW,
        PARENT_MESH_NAMES.MID1_NEW,
        PARENT_MESH_NAMES.MID2_NEW,
        PARENT_MESH_NAMES.END2_NEW,
      ],
      restrictions: {
        locomotive: {
          moveForward: false,
          moveBackward: false,
        },
        kirow: {
          moveForward: true,
          moveBackward: true,
        },
      },
    },
    {
      name: 'kirow-move-mid-1-org',
      comment: 'Kirows arm is extended. Moves "Mid1_org"',
      index: 4,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        PARENT_MESH_NAMES.END1_NEW,
        PARENT_MESH_NAMES.MID1_NEW,
        PARENT_MESH_NAMES.MID2_NEW,
        PARENT_MESH_NAMES.END2_NEW,
      ],
      restrictions: {
        locomotive: {
          moveForward: false,
          moveBackward: false,
        },
        kirow: {
          moveForward: true,
          moveBackward: true,
        },
      },
    },
    {
      name: 'kirow-move-mid-2-org',
      comment: 'Kirows arm is extended. Moves "Mid2_org"',
      index: 5,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        PARENT_MESH_NAMES.END1_NEW,
        PARENT_MESH_NAMES.MID1_NEW,
        PARENT_MESH_NAMES.MID2_NEW,
        PARENT_MESH_NAMES.END2_NEW,
      ],
      restrictions: {
        locomotive: {
          moveForward: false,
          moveBackward: false,
        },
        kirow: {
          moveForward: true,
          moveBackward: true,
        },
      },
    },
    {
      name: 'kirow-move-end-2-org',
      comment:
        'Kirows arm is extended. Moves "End2_org" at track 1 (furthest to the west)',
      index: 6,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        PARENT_MESH_NAMES.END1_NEW,
        PARENT_MESH_NAMES.MID1_NEW,
        PARENT_MESH_NAMES.MID2_NEW,
        PARENT_MESH_NAMES.END2_NEW,
      ],
      restrictions: {
        locomotive: {
          moveForward: false,
          moveBackward: false,
        },
        kirow: {
          moveForward: true,
          moveBackward: true,
        },
      },
    },
    // Part D [C in reverse]
    {
      name: 'kirow-prepare',
      comment: 'Kirows arm is extended. Prepare for lift',
      index: 7,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        PARENT_MESH_NAMES.END1_NEW,
        PARENT_MESH_NAMES.MID1_NEW,
        PARENT_MESH_NAMES.MID2_NEW,
        PARENT_MESH_NAMES.END2_NEW,
      ],
      restrictions: {
        locomotive: {
          moveForward: false,
          moveBackward: false,
        },
        kirow: {
          moveForward: true,
          moveBackward: true,
        },
      },
    },
    {
      name: 'kirow-move-end-2-new',
      comment:
        'Kirows arm is extended. Moves "End2_new" on train to track 1 (furthest to the west) [REVERSE ORDER]',
      index: 8,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        PARENT_MESH_NAMES.END1_NEW,
        PARENT_MESH_NAMES.MID1_NEW,
        PARENT_MESH_NAMES.MID2_NEW,
        // on train
        MESH_NAMES.END2_OMTRAIN,
      ],
      restrictions: {
        locomotive: {
          moveForward: false,
          moveBackward: false,
        },
        kirow: {
          moveForward: true,
          moveBackward: true,
        },
      },
    },
    {
      name: 'kirow-move-mid-1-new',
      comment:
        'Kirows arm is extended. Moves "Mid2_new" on train to new position',
      index: 9,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        PARENT_MESH_NAMES.END1_NEW,
        PARENT_MESH_NAMES.MID1_NEW,
        // on train
        MESH_NAMES.END2_OMTRAIN,
        MESH_NAMES.MID2_ONTRAIN,
      ],
      restrictions: {
        locomotive: {
          moveForward: false,
          moveBackward: false,
        },
        kirow: {
          moveForward: true,
          moveBackward: true,
        },
      },
    },
    {
      name: 'kirow-move-mid-2-new',
      comment:
        'Kirows arm is extended. Moves "Mid1_new" on train to new position',
      index: 10,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        PARENT_MESH_NAMES.END1_NEW,

        // on train
        MESH_NAMES.END2_OMTRAIN,
        MESH_NAMES.MID2_ONTRAIN,
        MESH_NAMES.MID1_ONTRAIN,
      ],
      restrictions: {
        locomotive: {
          moveForward: false,
          moveBackward: false,
        },
        kirow: {
          moveForward: true,
          moveBackward: true,
        },
      },
    },
    {
      name: 'kirow-move-end-2-new',
      comment:
        'Kirows arm is extended. Moves "End1_new" on train to new position to track 2 (furthest to the east)',
      index: 11,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        // on train
        MESH_NAMES.END2_OMTRAIN,
        MESH_NAMES.MID2_ONTRAIN,
        MESH_NAMES.MID1_ONTRAIN,
        MESH_NAMES.END1_ONTRAIN,
      ],
      restrictions: {
        locomotive: {
          moveForward: false,
          moveBackward: false,
        },
        kirow: {
          moveForward: true,
          moveBackward: true,
        },
      },
    },
    // Part D [B in reverse]
    {
      name: 'transition-reverse',
      comment: 'Shrink. Transition from track 1 to track 2. Attatch to train.',
      index: 11,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        // on train
        MESH_NAMES.END2_OMTRAIN,
        MESH_NAMES.MID2_ONTRAIN,
        MESH_NAMES.MID1_ONTRAIN,
        MESH_NAMES.END1_ONTRAIN,
      ],
      restrictions: {
        locomotive: {
          moveForward: false,
          moveBackward: false,
        },
        kirow: {
          moveForward: true,
          moveBackward: true,
        },
      },
    },
    // Part E
    {
      name: 'exit',
      comment: 'Shrink. Transition from track 1 to track 2. Attatch to train.',
      index: 12,
      hidden: [
        MESH_NAMES.CUBE,
        PARENT_MESH_NAMES.TESTLOOK,
        // on train
        MESH_NAMES.END2_OMTRAIN,
        MESH_NAMES.MID2_ONTRAIN,
        MESH_NAMES.MID1_ONTRAIN,
        MESH_NAMES.END1_ONTRAIN,
      ],
      restrictions: {
        locomotive: {
          moveForward: true,
          moveBackward: false,
          reasonBackward: 'Tåget måste åka framåt.',
        },
        kirow: {
          moveForward: false,
          moveBackward: false,
          reasonForward:
            'Spårbitarna är bytta. Uppdraget är således slutfört. Byt till loket för att fortsätta.',
          reasonBackward:
            'Spårbitarna är bytta. Uppdraget är således slutfört. Byt till loket för att fortsätta.',
        },
      },
    },
  ],
  path: PATHS.ANIMATION_TEST5,
  castShadow: false,
  receiveShadow: false,
  beginIndexAtZero: false,
});

export default contentOverlord;
