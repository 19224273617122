import { Mesh, MeshLambertMaterial, PlaneGeometry } from 'three';
import ViewerOverlordBase from '../../base';
import { POSITIONS } from '../../../../config';
/**
 * Init a the scene background.
 * This may be a skybox or a room environment.
 *
 * N.B. May conflict with `initSkybox`.
 * @deprecated WIP
 */
export default function initSceneBackground(this: ViewerOverlordBase) {
  const { viewerCoordinates } = this.positionOverlord;
  const material = new MeshLambertMaterial({
    color: 0x808080,
  });
  const geometry = new PlaneGeometry(
    POSITIONS.GROUND_SIZE,
    POSITIONS.GROUND_SIZE,
  );
  const ground = new Mesh(geometry, material);
  ground.rotation.x = -Math.PI * 0.5;
  ground.position.copy(viewerCoordinates);
  ground.position.setY(POSITIONS.ZERO_HEIGHT_POSITION);
  ground.matrixAutoUpdate = false;
  ground.updateMatrix();
  ground.receiveShadow = true;
  ground.userData.id = 'ground';
  ground.userData.name = 'Grå platta';
  this.visibilityOverlord.add('ground', ground);
  this.scene.add(ground);
}
