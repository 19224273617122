// eslint-disable-next-line
import { CameraHelper, PerspectiveCamera } from 'three';
import { POSITIONS } from '../../../../config';
import ViewerOverlordBase from '../../base';
/**
 * Init `perspectiveCamera`.
 * This projection mode is designed to mimic the way the human eye sees. It is the most common projection mode used for rendering a 3D scene.
 */
export default function initCamera(this: ViewerOverlordBase) {
  this.camera = new PerspectiveCamera(
    40,
    this.renderArea.clientWidth / this.renderArea.clientHeight,
    POSITIONS.CAMERA_NEAR,
    POSITIONS.CAMERA_FAR,
  );
  this.camera.position.copy(POSITIONS.CAMERA_POSITION);
  // const helper = new CameraHelper(this.camera);
  // this.scene.add(helper);
}
