/* eslint-disable */
import {
  AnimationAction,
  AnimationClip,
  AnimationMixer,
  LoopOnce,
  Mesh,
  MeshLambertMaterial,
  Object3D,
  Vector3,
} from 'three';

import contentOverlord from '../../../../config/content_overlord';
import ViewerOverlordBase from '../../base';
import { getAllChildren } from '../../../../utils/get_children';
import logMeshNames from '../../../../utils/log_mesh_names';
import { POSITIONS } from '../../../../config';
/**
 * Very dump animation controller.
 */
export class AnimationTest {
  public target: Object3D;

  public mixer: AnimationMixer;

  public action: AnimationAction;

  setTarget(target: Object3D) {
    this.target = target;
    this.mixer = new AnimationMixer(this.target);
  }

  setClip(animationClip: AnimationClip): void {
    if (this.target === undefined) return;
    if (this.mixer === undefined) return;
    this.action?.stop();
    this.action = this.mixer.clipAction(animationClip);
    this.action.loop = LoopOnce;
  }

  update(timeInSeconds: number): void {
    if (this.target === undefined) return;
    this.mixer.update(timeInSeconds);
  }

  play() {
    this.action.stop();
    this.action.play();
  }
}

// eslint-disable-next-line
const debugMaterial = new MeshLambertMaterial({
  color: 0x808080,
});

export default async function initAnimationTest(this: ViewerOverlordBase) {
  this.animationTest = new AnimationTest();

  const { animationContent } = contentOverlord; // All animation content
  const contents = Object.values(animationContent); // As `array`
  /**
   * Await all loading. Not checking if the file/path is wrong.
   */
  const gltfs = await Promise.all(
    Object.values(contents).map((i) => this.gltfLoader.loadAsync(i.path)),
  );

  for (let i = 0; i < contents.length; i++) {
    const content = contents[i]; // From `contentOverlord`
    const { name, contentToFollowIndex, selectableObjects } = content; // From `contentOverlord`
    const gltf = gltfs[i]; // Result from `GLTFLoader`
    const { scene, animations } = gltf;
    scene.scale.set(2, 2, 2); // Dunno, but this works :)
    this.gltf = scene;
    scene.name = content.name;
    scene.userData.id = content.id;
    const { viewerCoordinates } = this.positionOverlord;

    /**
     * Assume all children will receive and cast shadows.
     */
    getAllChildren(scene).forEach((i) => {
      i.receiveShadow = true;
      i.castShadow = true;
    });

    // logAnimationNames(animations)
    // logMeshNames(scene)

    scene.position.set(
      viewerCoordinates.x + POSITIONS.ANIMATION.x,
      viewerCoordinates.y + POSITIONS.ANIMATION.y,
      viewerCoordinates.z + POSITIONS.ANIMATION.z,
    ); // Arbitrary offset. TODO: Fix (!)
    this.contentToFollow = scene.children[contentToFollowIndex];
    this.directionalLight.target = this.contentToFollow;
    this.setSelectedObject(this.contentToFollow.name);
    this.selectableObjects = selectableObjects.map(
      (i) => scene.children[i.index],
    );
    this.selectableObjects.forEach((c) => {
      c.userData.parent = c;
      const parent = c;
      getAllChildren(c).forEach((cc) => {
        cc.userData.parent = parent;
      });
    });

    this.setContent(scene, animations);
    if (!this.debug) return;
    const animationTestFolder = this.gui.addFolder(name).close();
    /**
     * @deprecated WIP
     */
    // eslint-disable-next-line
    function dumpGLTF(this: ViewerOverlordBase) {
      // eslint-disable-next-line
      console.log(gltf);
    }
    /**
     * @deprecated WIP
     */
    // eslint-disable-next-line
    function dumpScene(this: ViewerOverlordBase) {
      // eslint-disable-next-line
      console.log(scene);
    }
    const viewerObjectBase = {
      dumpGLTF: dumpGLTF.bind(this),
      dumpScene: dumpScene.bind(this),
    };
    animationTestFolder.add(viewerObjectBase, 'dumpGLTF'); // Entire
    animationTestFolder.add(viewerObjectBase, 'dumpScene');
  }
}
