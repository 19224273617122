import {
  AnimationMixer,
  LoadingManager,
  PerspectiveCamera,
  Vector3,
  Scene,
  Quaternion,
  Group,
} from 'three';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import FinitefiniteStateMachine from '../../../base/animation_controller/finite_state_machine';
import AIInput from '../../../base/animation_controller/inputs/ai';
import KeyboardInput from '../../../base/animation_controller/inputs/keyboard';
import ReactInput from '../../../base/animation_controller/inputs/react';
import AnimationControllerProxy from '../../../base/animation_controller/proxy';

import AnimationControllerFSM from '../finite_state_machine';
import { PATHS, ANIMATION_STATES, DEFAULTS } from '../../../../../config';
import Animation from '../../../base/animation_controller/animation';

/**
 * The `AnimationController` must be aware of the `THREE` context.
 * The loader is supplied so it can share the same `LoadingManager`.
 */
interface AnimationControllerParamaters {
  camera: PerspectiveCamera;
  scene: Scene;
  loader: FBXLoader;
  modelPosition: Vector3;
}
export default class AnimationController {
  private params: AnimationControllerParamaters;

  private decceleration: Vector3;

  private acceleration: Vector3;

  private velocity: Vector3;

  private animations: { [key: string]: Animation };

  private input: KeyboardInput | AIInput | ReactInput;

  private finiteStateMachine: FinitefiniteStateMachine;

  private target: Group;

  private mixer: AnimationMixer;

  private manager: LoadingManager;

  /**
   * Setup hard coded `acceleration` and `decceleration` for a character animation.
   * Also, set the `FSM` by using a proxy (!).
   * @param params `AnimationControllerParamaters`
   */
  constructor(params: AnimationControllerParamaters) {
    this.params = params;
    this.input = new KeyboardInput();
    this.decceleration = new Vector3(-0.0005, -0.0001, -5.0);
    this.acceleration = new Vector3(1, 0.25, 50.0);
    this.velocity = new Vector3(0, 0, 0);
    this.animations = {};
    this.finiteStateMachine = new AnimationControllerFSM(
      new AnimationControllerProxy(this.animations),
    );
    this.loadModels();
  }

  /**
   * Load `FBX` models (because that's what I had available).
   * The main model is loaded first.
   * Each animation is loaded as a separate `FBX` model.
   */
  loadModels() {
    const { loader, modelPosition } = this.params;
    loader.setPath(PATHS.ANIMATION_PATH);
    loader.load(PATHS.ANIMATION_BASE_MODEL, (fbx) => {
      // fbx.position.set(0, GROUND_HEIGHT_POSITION + 0.1, 0);
      fbx.position.copy(modelPosition);
      fbx.scale.setScalar(0.1);
      fbx.rotation.y = Math.PI / 2;
      fbx.traverse((c) => {
        c.castShadow = false;
      });
      this.target = fbx;
      this.params.scene.add(this.target);
      this.mixer = new AnimationMixer(this.target);
      this.manager = new LoadingManager(); // Custom loading manager for each animation.
      this.manager.onLoad = () => {
        this.finiteStateMachine.setState(DEFAULTS.ANIMATION_STATE); // Set default animation state
      };
      const OnLoad = (name: string, anim: Group) => {
        const clip = anim.animations[0]; // N.B: Each model only has one animation (!)
        /**
         * Returns an AnimationAction for the passed clip, optionally using a root object different from the mixer's default root.
         * The first parameter can be either an AnimationClip object or the name of an AnimationClip.
         *
         * If an action fitting the clip and root parameters doesn't yet exist, it will be created by this method.
         * N.B: Calling this method several times with the same clip and root parameters always returns the same clip instance.
         */
        const action = this.mixer.clipAction(clip);
        this.animations[name] = {
          clip: clip as any,
          action,
        };
      };
      const animationLoader = new FBXLoader(this.manager); // Create a new `loader` with the `manager` for the animations
      animationLoader.setPath(PATHS.ANIMATION_PATH);
      animationLoader.load(PATHS.ANIMATION_WALK, (a) => {
        OnLoad(ANIMATION_STATES.WALK, a);
      });
      animationLoader.load(PATHS.ANIMATION_RUN, (a) => {
        OnLoad(ANIMATION_STATES.RUN, a);
      });
      animationLoader.load(PATHS.ANIMATION_IDLE, (a) => {
        OnLoad(ANIMATION_STATES.IDLE, a);
      });
      animationLoader.load(PATHS.ANIMATION_DANCE, (a) => {
        OnLoad(ANIMATION_STATES.DANCE, a);
      });
    });
  }

  /**
   * Custom update function for the model.
   * Handel acceleration, deccelaration and rotation of the model.
   * @param timeInSeconds `THREE.Clock.getDelta()`
   */
  update(timeInSeconds: number): void {
    if (!this.target) return;
    /**
     * Update the `FSM` first.
     * Update the `mixer` last.
     */
    this.finiteStateMachine.update(timeInSeconds, this.input);

    const { velocity } = this; // Previous velocity
    const frameDecceleration = new Vector3(
      velocity.x * this.decceleration.x,
      velocity.y * this.decceleration.y,
      velocity.z * this.decceleration.z,
    );
    frameDecceleration.multiplyScalar(timeInSeconds);
    frameDecceleration.z =
      Math.sign(frameDecceleration.z) *
      Math.min(Math.abs(frameDecceleration.z), Math.abs(velocity.z));

    velocity.add(frameDecceleration); // New velocity

    const controlObject = this.target;
    const Q = new Quaternion();
    const A = new Vector3();
    const R = controlObject.quaternion.clone();

    const acc = this.acceleration.clone();
    const input = this.input as KeyboardInput;

    if (input.keys.shift) acc.multiplyScalar(2.0); // Running has faster acceleration (duh)

    if (
      this.finiteStateMachine?.currentState?.name === ANIMATION_STATES.DANCE
    ) {
      acc.multiplyScalar(0.0); // Dance at the current place
    }
    if (input.keys.forward) velocity.z += acc.z * timeInSeconds;
    if (input.keys.backward) velocity.z -= acc.z * timeInSeconds;

    /**
     * Rotate object around the up axis with a given angel.
     * Uses `quaternion` because it's easier and you can use `TWEEN` in the future.
     */
    if (input.keys.left) {
      A.set(0, 1, 0);
      Q.setFromAxisAngle(
        A,
        4.0 * Math.PI * timeInSeconds * this.acceleration.y,
      );
      R.multiply(Q);
    }

    /**
     * Rotate object around the up axis with a given angel.
     * Uses `quaternion` because it's easier and you can use `TWEEN` in the future.
     */
    if (input.keys.right) {
      A.set(0, 1, 0);
      Q.setFromAxisAngle(
        A,
        4.0 * -Math.PI * timeInSeconds * this.acceleration.y,
      );
      R.multiply(Q);
    }

    controlObject.quaternion.copy(R); // Apply `rotation`

    const oldPosition = new Vector3();
    oldPosition.copy(controlObject.position);

    const forward = new Vector3(0, 0, 1);
    forward.applyQuaternion(controlObject.quaternion);
    forward.normalize();

    const sideways = new Vector3(1, 0, 0);
    sideways.applyQuaternion(controlObject.quaternion);
    sideways.normalize();

    sideways.multiplyScalar(velocity.x * timeInSeconds);
    forward.multiplyScalar(velocity.z * timeInSeconds);

    controlObject.position.add(forward); // Apply `rotation`
    controlObject.position.add(sideways); // Apply `rotation`

    oldPosition.copy(controlObject.position);
    if (!this.mixer) return;
    this.mixer.update(timeInSeconds);
  }
}
