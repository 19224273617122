/* eslint-disable */
import ViewerOverlordBase from '../../../base';
/**
 * @deprecated use `all animation`
 */
export default function updateSimpleGUI(this: ViewerOverlordBase) {
  this.animCtrls.forEach((ctrl) => ctrl.remove());
  this.animCtrls.length = 0;
  this.animFolder.domElement.style.display = 'none';
  // if (this.clips.length === 0) return;
  this.animFolder.domElement.style.display = '';
  this.animDictFolder.domElement.style.display = '';
  // eslint-disable-next-line
  const actionStates = (this.debugState.actionStates = {});
  this.clips.forEach((clip, clipIndex) => {
    // clip.name = `${clipIndex + 1}. ${clip.name}`;

    // Autoplay the first clip.
    let action;
    actionStates[clip.name] = false;

    // Play other clips when enabled.
    const ctrl = this.animFolder.add(actionStates, clip.name).listen();
    // eslint-disable-next-line
    ctrl.onChange((playAnimation) => {
      action = action || this.mixer.clipAction(clip);
      action.setEffectiveTimeScale(1);
      // eslint-disable-next-line
      playAnimation ? action.play() : action.stop();
    });
    this.animCtrls.push(ctrl);
  });
}
