import { Raycaster, Vector2 } from 'three';
import ViewerOverlordBase from '../../base';

export default function initRaycaster(this: ViewerOverlordBase) {
  this.raycaster = new Raycaster();
  this.raycaster.params.Mesh.threshold = 10; // Not working!
  this.mouse = new Vector2();
  this.contentToFollow = undefined;
  window.addEventListener('mousemove', this.onMouseMove.bind(this), false);
  window.addEventListener('pointerdown', this.onMouseDown.bind(this), false);
}
