import { ANIMATION_STATES } from '../../../../../../config';
import KeyboardInput from '../../../../base/animation_controller/inputs/keyboard';
import State from '../../../../base/animation_controller/state';
/**
 * Walk State
 */
export default class RunState extends State {
  constructor(parent) {
    super(parent);
    this.name = ANIMATION_STATES.RUN;
  }

  enter(prevState: State) {
    const curAction = this.parent.proxy.animations[this.name].action;
    if (!prevState) {
      curAction.play();
      return;
    }
    const prevAction = this.parent.proxy.animations[prevState.name].action;
    curAction.enabled = true;
    if (prevState.name === ANIMATION_STATES.WALK) {
      const ratio =
        curAction.getClip().duration / prevAction.getClip().duration;
      curAction.time = prevAction.time * ratio;
    } else {
      curAction.time = 0.0;
      curAction.setEffectiveTimeScale(1.0);
      curAction.setEffectiveWeight(1.0);
    }

    curAction.crossFadeFrom(prevAction, 0.5, true);
    curAction.play();
  }

  update(timeElapsed, input: KeyboardInput): void {
    if (input.keys.forward || input.keys.backward) {
      if (!input.keys.shift) this.parent.setState(ANIMATION_STATES.WALK);
      return;
    }
    this.parent.setState(ANIMATION_STATES.IDLE);
  }
}
