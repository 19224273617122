/* eslint-disable */
import FiniteStateMachine from '../finite_state_machine';
import AIInput from '../inputs/ai';
import KeyboardInput from '../inputs/keyboard';
import ReactInput from '../inputs/react';

/**
 *  Let’s say we have our friend `Mario` in our favorite video game.
 * So, `Mario` can do the following actions:
 * - Stand still
 * - Run
 * - Jump
 * - Duck
 *
 * We can see that `Mario` can do a lot of things,
 * and all of those things should be a specific state.
 *
 * However, we need to know exactly what `Mario` can do and how and when he can do these actions.
 * For example:
 * - Mario can stand still then run when we push the corresponding button,
 * - Mario **cannot** run while he is jumping.
 *
 * So, an input that doesn’t cause a change of state is ignored.
 */
export default class State {
  public parent: FiniteStateMachine;

  public name: string;

  constructor(parent) {
    this.parent = parent;
  }

  /**
   * Custom logic if `prevState` is supplied.
   * @param prevState
   */
  enter(prevState: State) {}

  /**
   * Some states needs additional clean up.
   */
  exit() {}

  /**
   * Update the current state. Check for input change.
   * @param timeElapsed `THREE.Clock.getDelta()`
   * @param input
   */
  update(timeElapsed: number, input: KeyboardInput | AIInput | ReactInput) {}

  /**
   * Can be used inside a callback.
   */
  finished(): void {}

  /**
   * Can be used inside a callback.
   */
  cleanup(): void {}
}
