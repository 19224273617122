import { Object3D } from 'three';
import { TilesRenderer } from '3d-tiles-renderer';
import ViewerOverlordBase from '../../base';
import { getAllChildren } from '../../../../utils/get_children';
import { PATHS } from '../../../../config';
/**
 * Init `3d-tiles-renderer`.
 * Make sure to apply a custom `onLoadModel` function.
 * @param this
 */
export default function initTilesRenderer(
  this: ViewerOverlordBase,
  disabled: boolean = true,
) {
  if (disabled) {
    // @ts-ignore
    this.tilesRenderer = {
      update: () => {},
    };
    return;
  }
  const { viewerCoordinates } = this.positionOverlord;
  this.tilesRenderer = new TilesRenderer(PATHS.TILES);
  this.tilesRenderer.setCamera(this.camera);
  this.tilesRenderer.setResolutionFromRenderer(this.camera, this.renderer);
  const onLoadModel = function (scene: Object3D) {
    getAllChildren(scene).forEach((child) => {
      child.matrixAutoUpdate = false;
      child.castShadow = false;
      child.receiveShadow = false;
    });
  };
  this.tilesRenderer.onLoadModel = onLoadModel;
  this.tilesRenderer.group.rotation.x = Math.PI;
  this.tilesRenderer.group.position.set(
    viewerCoordinates.x,
    viewerCoordinates.y + 50,
    viewerCoordinates.z + 200,
  );
  this.tilesRenderer.group.updateMatrix();
  this.tilesRenderer.manager.addHandler(/\.gltf$/, this.gltfLoader);
  this.dumpContentMangager.tile = this.tilesRenderer.group;
  this.scene.add(this.tilesRenderer.group);
}
