import Stats from 'stats.js';
import { Vector3 } from 'three';
import ViewerOverlordBase from '../../base';

const POSITION = new Vector3();
/**
 * Update `before` render.
 */
export function updateBeforeRender(this: ViewerOverlordBase) {
  const delta: number = this.clock.getDelta();
  this.camera.updateMatrixWorld();
  (this.stats as Stats).update();
  this.mixer?.update(delta);
  // this.raycaster.setFromCamera(this.mouse, this.camera);
  // intersects = this.raycaster.intersectObjects(this.scene.children, true);
  // this.hoveredObject = undefined;
  // if (intersects.length > 0) {
  //   intersect = intersects[0];
  //   const { parent } = intersect.object.userData;
  //   if (parent === undefined) return;
  //   this.hoveredObject = parent;
  //   this.selectedOutlineEffect.selection.set(getAllChildren(parent));
  // } else {
  //   this.contentToFollow === undefined
  //     ? this.selectedOutlineEffect.selection.set([])
  //     : this.selectedOutlineEffect.selection.set(getAllChildren(this.contentToFollow));
  // }

  if (this.contentToFollow) {
    this.contentToFollow.getWorldPosition(POSITION);
    // this.directionalLight.position.set(POSITION.x - 20, 100, POSITION.z + 60);
    // this.directionalLight.position.set(
    //   POSITION.x,
    //   this.directionalLight.position.y,
    //   POSITION.z,
    // );
    if (this.debugState?.followCrane) {
      if (this.lockCamera) {
        // this.camera.position.x = POSITION.x;
        // this.camera.position.z = POSITION.z;
      } else {
        this.controls.target.copy(POSITION);
        this.controls.update();
      }
    }
  }
}
/**
 * Update `after` render.
 */
export function updateAfterRender(this: ViewerOverlordBase) {}
