import Animation from '../animation';
/**
 * Don't communicate with the `AnimationController` directly.
 */
export default class AnimationControllerProxy {
  public animations: Animation;

  constructor(animations) {
    this.animations = animations;
  }
}
