/**
 * DEV Secrets, do not use in production!
 */
export const DEV_MAPBOX_API_KEY =
  'pk.eyJ1IjoidGVudG9uZSIsImEiOiJjazBwNHU4eDQwZzE4M2VzOGhibWY5NXo5In0.8xpF1DEcT6Y4000vNhjj1g';
export const DEV_HEREMAPS_APP_ID = 'HqSchC7XT2PA9qCfxzFq';
export const DEV_HEREMAPS_APP_CODE = '5rob9QcZ70J-m18Er8-rIA';
export const DEV_BING_API_KEY =
  'AuViYD_FXGfc3dxc0pNa8ZEJxyZyPq1lwOLPCOydV3f0tlEVH-HKMgxZ9ilcRj-T';
export const DEV_MAPTILER_API_KEY = 'B9bz5tIKxl4beipiIbR0';
export const OPEN_MAP_TILES_SERVER_MAP = '';
