export const H1: string = /* @__PURE__ */ 'Vossloh Planner';
export const P_1: string =
  /* @__PURE__ */
  'Vossloh är Nordens ledande leverantör och ett av världens ledande företag';
export const P_2: string =
  'med heltäckande kompetens inom allt som rör modern spårteknik.';
export const USERNAME: string = /* @__PURE__ */ 'Användarnamn';
export const WRONG_USERNAME: string =
  /* @__PURE__ */ 'Vänligen ange rätt användarnamn';
export const PASSWORD: string = /* @__PURE__ */ 'Lösenord';
export const WRONG_PASSWORD: string =
  /* @__PURE__ */ 'Vänligen ange rätt lösenord';
export const REMEMBER: string = /* @__PURE__ */ 'Kom ihåg mig';
export const LOGIN: string = /* @__PURE__ */ 'Logga in';
export const FORGOT: string = /* @__PURE__ */ 'Jag har glömt mitt lösenord';
export const ERROR_MESSAGE: string =
  /* @__PURE__ */
  'Inloggningen misslyckades.';
export const LOADING: string =
  /* @__PURE__ */
  'Laddar ';
export const LOADING_STRINGS: string[] =
  /* @__PURE__ */
  [
    'spår',
    'Flen',
    'nya X2000',
    'Kirow-kran',
    'kartor',
    'spårkartor',
    'lok',
    'nya växlar',
    'databas',
    'Vossloh Planner',
    'ljudfiler',
    'modeller',
    'foton',
    'fotogrammterimodeller',
    'punktmoln',
    'texturer',
    'material',
    'egenskaper',
    'HDR-bilder',
    'drivrutiner',
    'stationshuset',
    'perrongen',
    'spår 1',
    'spåret',
    'stambanan',
    'Mälartåg',
    'avgångar',
    'ortofoto',
    'laserskanningar',
    'kontrollpunkter för SWEREF99',
    'stilmallar',
    'skriptspråk',
    'märkspråk',
  ];
