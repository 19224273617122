/* eslint-disable */
import {
  MapView,
  UnitsUtils,
  BingMapsProvider,
  MapBoxProvider,
  HereMapsProvider,
} from '../../base/geo_three/Main';
import { PLANAR } from 'geo-three';

import { Object3D, Vector3 } from 'three';
import { POSITIONS } from '../../../../config';
import ViewerOverlordBase from '../../base';
import PositionOverlord from '../../base/position_overlord';
import OpenStreetMapsProvider from './map_providers/open_street_map';
import OpenRailwayMapsProvider from './map_providers/open_railway_map';
import {
  DEV_BING_API_KEY,
  DEV_HEREMAPS_APP_CODE,
  DEV_HEREMAPS_APP_ID,
  DEV_MAPBOX_API_KEY,
} from './secrets';

/**
 * @deprecated WIP
 */
export default function initMap(this: ViewerOverlordBase) {
  this.positionOverlord = new PositionOverlord();
  this.positionOverlord.realLifeCoordinates = new Vector3(
    POSITIONS.OSM_POSITION_X,
    0,
    POSITIONS.OSM_POSITION_Y,
  );
  const sphericalCoordinates = UnitsUtils.datumsToSpherical(
    POSITIONS.OSM_POSITION_X,
    POSITIONS.OSM_POSITION_Y,
  );
  // this.positionOverlord.viewerCoordinates = new Vector3(
  //   sphericalCoordinates.x,
  //   0,
  //   -sphericalCoordinates.y,
  // );
  this.positionOverlord.viewerCoordinates = new Vector3();
  // Ugly
  const bingMapsProvider = new BingMapsProvider(
    DEV_BING_API_KEY,
    BingMapsProvider.AERIAL,
  );
  // Ok, yet ugly
  const mapBoxProvider = new MapBoxProvider(
    DEV_MAPBOX_API_KEY,
    'mapbox.satellite',
    MapBoxProvider.MAP_ID,
    'jpg70',
    false,
  );
  // OK quality
  // const hereProvider = new HereMapsProvider(
  //   DEV_HEREMAPS_APP_ID,
  //   DEV_HEREMAPS_APP_CODE,
  //   'aerial',
  //   'satellite.day',
  //   'jpg',
  // );
  const osmProvider = new OpenStreetMapsProvider();
  const ormProvider = new OpenRailwayMapsProvider();
  // Create the map view and add it to your THREE scene
  this.map = new MapView(PLANAR, ormProvider);
  (this.map as Object3D).position.y = -1;
  this.dumpContentMangager.map = this.map;
  (this.map.position as Vector3).set(
    -sphericalCoordinates.x,
    POSITIONS.ZERO_HEIGHT_POSITION + 0.5,
    sphericalCoordinates.y + 200,
  );

  this.map.userData.id = 'orm';
  this.map.userData.name = 'Spårkarta';
  this.visibilityOverlord.add('orm', this.map);
  this.scene.add(this.map);
  this.map.updateMatrixWorld(true);

  this.controls.target.copy(this.positionOverlord.viewerCoordinates);
  this.camera.position.set(
    this.positionOverlord.viewerCoordinates.x,
    1_000,
    this.positionOverlord.viewerCoordinates.z,
  );
  this.camera.updateMatrix();
  this.controls.update();
}
