/* eslint-disable */
import Stats from 'stats.js';
import GUI from 'lil-gui';
import ViewerOverlordBase from '../../base';
import { IDENTIFIERS } from '../../../../config';
import environments from '../../base/environment';
/**
 * Debug with `lil-gui` (dat.GUI)
 * @param this
 * @returns
 */
export default function initDebug(this: ViewerOverlordBase) {
  // --- init ---
  {
    this.debugState = {
      followCrane: false,
      environment: environments[1].name,
      background: true,
      playbackSpeed: 1.0,
      actionStates: {},
      sequenceStates: {},
      wireframe: false,
      skeleton: false,
      grid: false,
      // Lights
      addLights: true,
      exposure: 1.0,
      textureEncoding: 'sRGB',
      ambientIntensity: 0.3,
      ambientColor: 0xffffff,
      directIntensity: 0.8 * Math.PI,
      directColor: 0xffffff,
      bgColor1: '#ffffff',
      bgColor2: '#353535',
    };

    if (!this.debug) {
      this.stats = {
        update: () => {},
      };
      return;
    }

    this.stats = new Stats();
    this.stats.dom.id = IDENTIFIERS.STATS;
    document.body.appendChild(this.stats.dom);
    this.gui = new GUI();
  }
  // --- dump ---
  {
    function dumpThis(this: ViewerOverlordBase) {
      console.log(this);
    }
    function dumpCamera(this: ViewerOverlordBase) {
      console.log(this.camera);
    }
    function dumpRenderer(this: ViewerOverlordBase) {
      console.log(this.renderer);
    }
    function dumpControls(this: ViewerOverlordBase) {
      console.log(this.controls);
    }
    function dumpTilesRenderer(this: ViewerOverlordBase) {
      console.log(this.tilesRenderer);
    }
    function dumpAnimationController(this: ViewerOverlordBase) {
      console.log(this.animationController);
    }
    function dumpScene(this: ViewerOverlordBase) {
      console.log(this.scene);
    }
    const dumpFolder = this.gui.addFolder('dump').close();
    const dumpObject = {
      this: dumpThis.bind(this),
      camera: dumpCamera.bind(this),
      renderer: dumpRenderer.bind(this),
      controls: dumpControls.bind(this),
      tilesRenderer: dumpTilesRenderer.bind(this),
      animationController: dumpAnimationController.bind(this),
      dumpScene: dumpScene.bind(this),
    };
    dumpFolder.add(dumpObject, 'this');
    dumpFolder.add(dumpObject, 'camera');
    dumpFolder.add(dumpObject, 'renderer');
    dumpFolder.add(dumpObject, 'controls');
    dumpFolder.add(dumpObject, 'tilesRenderer');
    dumpFolder.add(dumpObject, 'animationController');
    dumpFolder.add(dumpObject, 'dumpScene');
  }
  // --- viewer ---
  {
    const viewerFolder = this.gui.addFolder('viewer').close();
    function freeze(this: ViewerOverlordBase) {
      this.freeze = !this.freeze;
    }
    const viewerObject = {
      toggleFreeze: freeze.bind(this),
    };
    viewerFolder.add(viewerObject, 'toggleFreeze');
  }
}
