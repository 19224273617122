import { Content, ContentProps } from '../base';
/**
 * Hold a reference (id) to the animation clip [WIP].
 */

export interface AnimationSequence {
  comment: string;
  name: string;
  index: number;
  hidden?: string[];
  restrictions: {
    locomotive: {
      moveForward: boolean;
      moveBackward: boolean;
      reasonForward?: string;
      reasonBackward?: string;
    };
    kirow: {
      moveForward: boolean;
      moveBackward: boolean;
      reasonForward?: string;
      reasonBackward?: string;
    };
  };
}

export interface AnimationContentProps extends ContentProps {
  animations: AnimationSequence[];
  beginIndexAtZero: boolean;
  contentToFollowIndex: number;
  visibility: {
    post: string[];
    pre: string[];
  };
  selectableObjects: {
    id: string;
    name: string;
    index: number;
  }[];
}
/**
 * GLTFs as with both model (`gltf.scene`) and animation (`gltf.animations`).
 */
export class AnimationContent extends Content {
  animations: AnimationSequence[];

  beginIndexAtZero: boolean;

  contentToFollowIndex: number;

  visibility: { post: string[]; pre: string[] };

  selectableObjects: {
    id: string;
    name: string;
    index: number;
  }[];

  constructor(props: AnimationContentProps) {
    super(props);
    this.type = 'animation';
    const {
      animations,
      beginIndexAtZero,
      contentToFollowIndex,
      visibility,
      selectableObjects,
    } = props;
    this.animations = animations;
    this.visibility = visibility;
    this.beginIndexAtZero = beginIndexAtZero;
    this.contentToFollowIndex = contentToFollowIndex;
    this.selectableObjects = selectableObjects;
  }
}
