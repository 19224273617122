import { LoadingManager } from 'three';
import LoggerMcLog from '../../../../../utils/logger_mc_log';
import ViewerOverlordBase from '../../../base';

/**
 *
 * @param this
 */
export default function initLoadingManager(this: ViewerOverlordBase) {
  this.loadingManager = new LoadingManager();
  const onStart = function (url, itemsLoaded, itemsTotal) {
    // eslint-disable-next-line
    LoggerMcLog.notImporant(
      `Started loading file: ${url}.\nLoaded ${itemsLoaded} of ${itemsTotal} files.`,
    );
  };
  const onLoad = function () {
    // eslint-disable-next-line
    LoggerMcLog.notImporant('Loading complete!');
  };
  const onProgress = function (url, itemsLoaded, itemsTotal) {
    // eslint-disable-next-line
    LoggerMcLog.notImporant(
      `Loading file: ${url}.\nLoaded ${itemsLoaded} of ${itemsTotal} files (${
        (+itemsLoaded / +itemsTotal) * 100
      }%)`,
    );
    this.setPercent(Math.round((+itemsLoaded / +itemsTotal) * 100));
  };
  const onError = function (url) {
    // eslint-disable-next-line
    LoggerMcLog.notImporant(`There was an error loading ${url}`);
  };
  this.loadingManager.onStart = onStart.bind(this);
  this.loadingManager.onLoad = onLoad.bind(this);
  this.loadingManager.onProgress = onProgress.bind(this);
  this.loadingManager.onError = onError.bind(this);
}
