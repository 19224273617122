import MapProvider from '../../../../base/map/map_provider';

/**
 * Open street maps tile server.
 *
 * Works with any service that uses a address/zoom/x/y.format URL for tile access.
 */
export default class OpenRailwayMapsProvider extends MapProvider {
  private servers: string[];

  public constructor() {
    super();
    this.servers = ['a', 'b', 'c'];
    this.maxZoom = 19;
  }

  private generateAddres(zoom: number, x: number, y: number) {
    const server =
      this.servers[Math.floor(Math.random() * this.servers.length)];
    return `https://${server}.tiles.openrailwaymap.org/standard/${zoom}/${x}/${y}.png`;
  }

  public fetchTile(zoom: number, x: number, y: number): Promise<any> {
    return new Promise<HTMLImageElement>((resolve, reject) => {
      const image = document.createElement('img');
      const onload = function () {
        resolve(image);
      };
      image.onload = onload;
      const onerror = function () {
        reject();
      };
      image.onerror = onerror;
      image.crossOrigin = '';
      image.src = this.generateAddres(zoom, x, y);
    });
  }
}
