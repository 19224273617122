import { Vector3 } from 'three';

export const GROUND_HEIGHT_POSITION: number = -53.5;

export const PROJECT_START_POSITION = new Vector3(0, 0, 0);
export const OSM_SCALE = 1 / 2;
export const OSM_POSITION = new Vector3(-72, -53.5, 22);
export const CAMERA_POSITION = new Vector3(0, 2 + GROUND_HEIGHT_POSITION, 20);
export const CAMERA_NEAR = 1;
export const CAMERA_FAR = 1e12;
export const CONTROLS_TARGET = new Vector3(0, 0.5 + GROUND_HEIGHT_POSITION, 0);
export const CONTROLS_MAX_DISTANCE: number = 10_000;
export const GROUND_SIZE = 4_000; // PlaneGeometry
export const SKYBOX_SIZE = 6_000; // BoxGeometry
// See: https://www.openstreetmap.org/#map=16/59.0565/16.5881&layers=H
export const OSM_POSITION_ZOOM: number = 16;
export const OSM_POSITION_X: number = 59.0565;
export const OSM_POSITION_Y: number = 16.5881;
export const ZERO_HEIGHT_POSITION: number = 1.2;
export const ANIMATION: Vector3 = new Vector3(80, 110 + 0.1, -20);
