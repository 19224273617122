import { Mesh } from 'three';
// eslint-disable-next-line
let MATERIALS = /* @__PURE__ */ [];
export default function traverseMaterials(object: Mesh, callback: any) {
  object.traverse((node: Mesh) => {
    if (!(node as any).isMesh) return;
    (Array.isArray(node.material) ? node.material : [node.material]).forEach(
      callback,
    );
  });
}
