export interface ContentProps {
  id: string;
  name: string;
  comment: string;
  path: string;
  castShadow: boolean;
  receiveShadow: boolean;
  reflectivity?: number;
}
/**
 * Base for a content to be loaded by a `THREE.Loader`.
 */
export class Content {
  public id: string;

  public name: string;

  public comment: string;

  public path: string;

  public type: string;

  public castShadow: boolean;

  public receiveShadow: boolean;

  public reflectivity: number;

  constructor({
    id,
    name,
    comment,
    path,
    castShadow,
    receiveShadow,
    reflectivity,
  }: ContentProps) {
    this.type = 'generic';
    this.id = id;
    this.name = name;
    this.comment = comment;
    this.path = path;
    this.castShadow = castShadow;
    this.receiveShadow = receiveShadow;
    this.reflectivity = reflectivity;
  }
}
