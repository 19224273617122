import ViewerOverlordBase from '..';

export default function resize(this: ViewerOverlordBase) {
  this.camera.aspect =
    this.renderArea.clientWidth / this.renderArea.clientHeight;
  this.camera.updateProjectionMatrix();
  this.renderer.setSize(
    this.renderArea.clientWidth,
    this.renderArea.clientHeight,
  );
}
