import { PATHS } from '../../../../config';
import ViewerOverlordBase from '../../base';
import environments from '../../base/environment';

export default function updateEnvironment(this: ViewerOverlordBase) {
  const environment = environments.filter(
    (entry) => entry.name === this.debugState.environment,
  )[0];

  const { scene, vignette } = this;

  this.getCubeMapTexture(environment).then(({ envMap }) => {
    // eslint-disable-next-line
    !envMap || !this.debugState.background
      ? scene.add(vignette)
      : scene.remove(vignette);
    scene.environment = envMap;
    const format = '.jpg';
    const urls = [
      `${PATHS.SKYBOX}px${format}`,
      `${PATHS.SKYBOX}nx${format}`,
      `${PATHS.SKYBOX}py${format}`,
      `${PATHS.SKYBOX}ny${format}`,
      `${PATHS.SKYBOX}pz${format}`,
      `${PATHS.SKYBOX}nz${format}`,
    ];
    const texture = this.cubeTextureLoader.load(urls);
    scene.background = texture;
    // scene.background = debugState.background ? envMap : null;
  });
}
