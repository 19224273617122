import * as THREE from 'three';
import { sRGBEncoding, WebGLRenderer } from 'three';
import { IDENTIFIERS } from '../../../../config';
import ViewerOverlordBase from '../../base';
/**
 * Init the `WebGL` renderer.
 */
export default function initRenderer(this: ViewerOverlordBase) {
  THREE.Cache.enabled = true;
  this.renderer = new WebGLRenderer({
    powerPreference: 'high-performance',
    // preserveDrawingBuffer: true,
    antialias: false,
    stencil: false,
    depth: false,
  });
  this.renderer.autoClear = false;
  this.renderer.setSize(
    this.renderArea.clientWidth,
    this.renderArea.clientHeight,
  );
  this.renderer.setClearColor(0x000000);
  this.renderer.outputEncoding = sRGBEncoding;
  this.renderer.shadowMap.enabled = true;
  this.renderer.domElement.id = IDENTIFIERS.CANVAS;
  this.renderArea.appendChild(this.renderer.domElement);
}
