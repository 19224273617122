import { ANIMATION_STATES } from '../../../../../config';
import FiniteStateMachine from '../../../base/animation_controller/finite_state_machine';
import AnimationControllerProxy from '../../../base/animation_controller/proxy';
import DanceState from '../states/dance';
import IdleState from '../states/idle';
import RunState from '../states/run';
import WalkState from '../states/walk';

export default class AnimationControllerFSM extends FiniteStateMachine {
  /**
   * Don't communicate with the `AnimationController` directly - use a proxy!
   */
  constructor(proxy: AnimationControllerProxy) {
    super();
    this.proxy = proxy;
    this.addState(ANIMATION_STATES.IDLE, IdleState);
    this.addState(ANIMATION_STATES.WALK, WalkState);
    this.addState(ANIMATION_STATES.RUN, RunState);
    this.addState(ANIMATION_STATES.DANCE, DanceState);
  }
}
