const sperator: string = '_';
const index: number = 0;
/**
 * ```
 * 3_svangbas_move1
 * ```
 * @param name
 * @returns sqeuence number
 */
const getSequenceNumber = (name: string): string => {
  return name.split(sperator)[index];
};

export default getSequenceNumber;
