import { AnimationMixer, Clock, Object3D } from 'three';
import ViewerOverlordBase from '../../base';
/**
 * Init animation mixer.
 * @deprecated Use `initAnimationController`
 */
export default function initAnimationMixer(this: ViewerOverlordBase) {
  const object = new Object3D();
  this.mixer = new AnimationMixer(object);
  this.clock = new Clock();
}
