import * as React from 'react';
import './styles/index.less';

const WAVE_ID: string = /* @__PURE__ */ '48916f63-9dfc-4065-8c34-6f103b17c121';
const X_COORD: string = /* @__PURE__ */ '48';

/**
 * Waves with `use` offset.
 * @param props
 * @returns
 */
export default function SVGComponent() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      className="waves"
      preserveAspectRatio="none"
      viewBox="0 24 150 40"
    >
      <defs>
        <path
          id={WAVE_ID}
          d="M-160 44c30 0 58-18 88-18s58 18 88 18 58-18 88-18 58 18 88 18v44h-352z"
        />
      </defs>
      <g className="moving-waves">
        <use
          x={X_COORD}
          y="-1"
          fill="rgba(255,255,255,0.40"
          xlinkHref={`#${WAVE_ID}`}
        />
        <use
          x={X_COORD}
          y="3"
          fill="rgba(255,255,255,0.35)"
          xlinkHref={`#${WAVE_ID}`}
        />
        <use
          x={X_COORD}
          y="5"
          fill="rgba(255,255,255,0.25)"
          xlinkHref={`#${WAVE_ID}`}
        />
        <use
          x={X_COORD}
          y="8"
          fill="rgba(255,255,255,0.20)"
          xlinkHref={`#${WAVE_ID}`}
        />
        <use
          x={X_COORD}
          y="13"
          fill="rgba(255,255,255,0.15)"
          xlinkHref={`#${WAVE_ID}`}
        />
        <use
          x={X_COORD}
          y="16"
          fill="rgba(255,255,255,0.95"
          xlinkHref={`#${WAVE_ID}`}
        />
      </g>
    </svg>
  );
}
