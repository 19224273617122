import { notification } from 'antd';
// import * as React from 'react';
import { AnimationAction, LoopOnce } from 'three';
import { IDENTIFIERS } from '../../../../config';
import contentOverlord from '../../../../config/content_overlord';
import getSequenceNumber from '../../../../utils/get_sequence_number';
import LoggerMcLog from '../../../../utils/logger_mc_log';
import ViewerOverlordBase from '../../base';
import SequenceOverlord from '../../base/sequence_overlord';

export default function updateSequenceController(this: ViewerOverlordBase) {
  const { animationContent } = contentOverlord; // All animation content
  const contents = Object.values(animationContent); // As `array`
  const content = contents[contents.length - 1]; // Assume only one animation is active
  const { animations, visibility } = content;
  this.sequenceOverlord = new SequenceOverlord(
    this.gltf,
    animations,
    visibility,
    this.mixer,
  );
  type NewType = AnimationAction;

  const actionDictionary: { [key: string]: NewType[] } = {};
  // eslint-disable-next-line
  this.clips.forEach((clip, clipIndex) => {
    // eslint-disable-next-line
    if (+getSequenceNumber(clip.name) === NaN) return; // Some sequences are not a number
    const reference = actionDictionary[getSequenceNumber(clip.name)]; // Check if sequence exist
    if (reference === undefined) {
      actionDictionary[getSequenceNumber(clip.name)] = [];
    }
    const sequence: AnimationAction[] =
      actionDictionary[getSequenceNumber(clip.name)];
    const action: AnimationAction = this.mixer.clipAction(clip);

    action.loop = LoopOnce;
    action.clampWhenFinished = true; // Don't reset
    action.zeroSlopeAtEnd = false;
    action.zeroSlopeAtStart = false;
    sequence.push(action);
  });
  this.sequenceOverlord.actionDictionary = actionDictionary;
  /**
   * Handle next action.
   */
  this.playNextAction = () => {
    this.sequenceOverlord.isAtEnd = false;
    // Will always trigger when a clip is finished
    this.sequenceOverlord.finishedEvent();
    // Check if any action is still running
    if (!this.sequenceOverlord.checkNumberOfActionsInSequence()) return;
    // // Check if `currentActionIndex` is in range.
    // if (this.sequenceOverlord.checkCurrentActionIndex()) return;
    // OK, increment !
    this.sequenceOverlord.isAtEnd = true;
    this.sequenceOverlord.increment();
    if (!this.sequenceOverlord.handleRestrictions(this.contentToFollow)) {
      this.mixer.timeScale = 0;
      this.setPlaybackSpeedEvent(0);
      return;
    }
    this.sequenceOverlord.handleVisibility();
    this.sequenceOverlord.updatePreviousActionsInSequence();
    this.sequenceOverlord.stopPreviousActionsInSequence();
    this.sequenceOverlord.updateCurrentActionsInSequence();
    this.sequenceOverlord.playCurrentActionsInSequence();
    LoggerMcLog.gandalf(
      animations[this.sequenceOverlord.currentActionIndex].comment,
    );
  };
  // Init
  this.sequenceOverlord.handleInitalVisibility();

  const alert = (messageContent: string) => {
    notification.warn({
      message: messageContent,
      duration: 10,
      key: IDENTIFIERS.ALERT,
    });

    // message.error({
    //   // content: LOCALES.SEQUENCE_OVERLORD.ERROR_TITLE,
    //   content: (
    //     <div style={{ paddingTop: 10, paddingBottom: 10 }}>
    //       {messageContent}
    //     </div>
    //   ),
    //   duration: 10,
    //   key: IDENTIFIERS.ALERT,
    //   style: {
    //     marginTop: '20vh',
    //   },
    // });
  };

  this.sequenceOverlord.alert = alert;

  // Prepare for flight !
  const playAllFunction = () => {
    this.mixer.addEventListener('finished', this.playNextAction);
    this.sequenceOverlord.currentActionIndex = -1; // Must be run first time
    this.sequenceOverlord.status[this.sequenceOverlord.currentActionIndex] = 1; // Must be run first time
    this.playNextAction();
  };
  playAllFunction();
  this.mixer.timeScale = 0; // pause everything
}
