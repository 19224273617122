import { FirebaseApp, initializeApp } from 'firebase/app';
// import { getAnalytics } from 'firebase/analytics';
import { getFirestore, collection, query, getDocs } from 'firebase/firestore';
import {
  signInWithEmailAndPassword,
  getAuth,
  setPersistence,
  browserSessionPersistence,
} from 'firebase/auth';

interface FirebaseConfig {
  apiKey: string;
  authDomain: string;
  databaseURL: string;
  projectId: string;
  storageBucket: string;
  messagingSenderId: string;
  appId: string;
  measurementId: string;
}
const firebaseConfig: FirebaseConfig = /* @__PURE__ */ {
  apiKey: 'AIzaSyDpXfHm_qWmj6M2wmtCTlbMJ2GN3JAn0hA',
  authDomain: 'omnitwin-voss-dev.firebaseapp.com',
  databaseURL:
    'https://omnitwin-voss-dev-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'omnitwin-voss-dev',
  storageBucket: 'omnitwin-voss-dev.appspot.com',
  messagingSenderId: '1046421801915',
  appId: '1:1046421801915:web:b2d54dd4721c6d2fb0129e',
  measurementId: 'G-FL5VYRD1HD',
};
/**
 * Get the configuration for `Firebase JavaScript SDK` (v7.20.0 and later).
 * The config is considered `public`, no need to hide
 * @returns `firebaseConfig`
 */
export const getFirebaseConfig = (): FirebaseConfig => firebaseConfig;
export class Firebase {
  private instance;

  private firebaseApp: FirebaseApp;

  constructor() {
    if (this.instance) {
      return this.instance;
    }
    this.firebaseApp = initializeApp(getFirebaseConfig());

    this.instance = this;
  }

  async getDB() {
    const db = getFirestore(this.firebaseApp);
    const q = query(collection(db, 'models'));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach((doc) => {
      // doc.data() is never undefined for query doc snapshots
      // eslint-disable-next-line
      console.log(doc.id, ' => ', doc.data());
    });
  }

  async auth(
    username: string,
    password: string,
    // eslint-disable-next-line
    remember: string,
  ): Promise<boolean> {
    const auth = getAuth(this.firebaseApp);
    let isAuth = false;
    try {
      await setPersistence(auth, browserSessionPersistence).then(() => {
        return signInWithEmailAndPassword(auth, username, password);
      });
      isAuth = true;
    } catch (error) {
      // eslint-disable-next-line
      console.log('error :>> ', error);
    }
    return isAuth;
  }

  onAuthStateChanged(cb: (user: any) => void) {
    const auth = getAuth(this.firebaseApp);
    // eslint-disable-next-line
    auth.onAuthStateChanged(function (user) {
      cb(user);
    });
  }
}

export default Firebase;
