/* eslint-disable */
import { AnimationAction, AnimationClip, LoopOnce } from 'three';
import ViewerOverlordBase from '../../base';
/**
 * Update `lil-gui` with correct animation names
 */
export default function updateGUI(this: ViewerOverlordBase) {
  this.updateSimpleGUI();
  this.updateGroupedGUI();
  this.updatePlayAllGUI();
}
