/* eslint-disable */
import {
  AnimationAction,
  AnimationMixer,
  Group,
  LoopOnce,
  Object3D,
} from 'three';
import { LOCALES } from '../../../../config';
import { AnimationSequence } from '../../../../config/content_overlord/animation';
import { getAllChildren } from '../../../../utils/get_children';
import LoggerMcLog from '../../../../utils/logger_mc_log';

/**
 * Overlord (manager) that keeps track of all sequences ( = set of actions).
 * The `SequenceOverlord` keeps track of:
 *
 * - What to play
 * - When to play
 *
 * It also determines if a set of action can be played (see `handleRestrictions`).
 *
 * N.B: The `mixer` is still responsible for mixing the actions.
 */
export default class SequenceOverlord {
  min: number;

  max: number;

  isForward: boolean;

  currentActionIndex: number;

  private _actionDictionary: { [key: string]: AnimationAction[] };

  private _actionArray: AnimationAction[][];

  numberOfActionsInSequence: number;

  prevActionsInSequence: AnimationAction[];

  currentActionsInSequence: AnimationAction[];

  status: { [key: string]: number };

  private _clipNames: { [key: string]: string[] };

  gltf: Group;

  animations: AnimationSequence[];

  visibility: {
    post: string[];
    pre: string[];
  };

  isAtEnd: boolean;

  hasInit: boolean;

  mixer: AnimationMixer;

  constructor(gltf, animations, visibility, mixer) {
    this.mixer = mixer;
    this.gltf = gltf;
    this.animations = animations;
    this.visibility = visibility;
    this.min = 0;
    this.max = 100;
    this.numberOfActionsInSequence = 0;
    this.currentActionIndex = 0;
    this.isForward = true;
    this._actionDictionary = {};
    this._clipNames = {};
    this._actionArray = [];
    this.status = {};
    this.isAtEnd = false;
    this.hasInit = false;
  }

  /**
   * Increment the `currentActionIndex` relative to the time.
   */
  increment(): void {
    this.currentActionIndex = this.isForward
      ? this.currentActionIndex + 1
      : this.currentActionIndex - 1;
  }

  /**
   * Set both `actionDictionary` and `actionArray`.
   * Generate `clipNames` (used for debugging).
   *
   * The `actionArray` is just the values of the dictionary
  
   */
  set actionDictionary(actionDictionary) {
    this._actionDictionary = actionDictionary;
    this._actionArray = Object.values(actionDictionary);
    this.max = this._actionArray.length - 1 - 2; // Remove `pre` and `post`
    for (let i = 0; i < this._actionArray.length; i++) {
      this.status[i] = 0;
      this._clipNames[`${i}`] = this._actionArray[i].map((k) => {
        const name = k.getClip().name.split('_');
        name.shift();
        return name.join('_');
      });
    }
    // console.log(' this._clipNames :>> ', JSON.stringify(this._clipNames));
  }

  /**
   * Don't access the property directly (!)
   */
  get actionDictionary() {
    return this._actionDictionary;
  }

  /**
   * Don't access the property directly (!)
   */
  get actionArray(): AnimationAction[][] {
    return this._actionArray;
  }

  /**
   * Check if the sequence is done.
   * @returns Whether we're at the end
   */
  checkCurrentActionIndex(): boolean {
    return this.currentActionIndex === this.max;
  }

  /**
   * Check if all actions in the `currentActionIndex` has finished.
   *
   * N.B. This is just a simple counter (!)
   * @returns Whether all actions has finished.
   */
  checkNumberOfActionsInSequence(): boolean {
    return this.status[this.currentActionIndex] === 0;
  }

  /**
   * Update the property `this.currentActionsInSequence`.
   * This property holds the correct information.
   * However, the `currentActionIndex` can be out of sync.
   *
   * So: "Håll tungan rätt i munnen"
   */
  updateCurrentActionsInSequence(): void {
    LoggerMcLog.error(`Playing: ${this.currentActionIndex}`);
    this.currentActionsInSequence = this._actionArray[this.currentActionIndex];
  }

  /**
   * Prevent tracks from moving.
   * N.B: The tele should move!
   */
  stopPreviousActionsInSequence() {
    const namesToIgnore: string[] = [
      'kirow',
      'Kirow',
      'svangbas',
      'svanhbas',
      'lyftok',
    ];

    this.prevActionsInSequence?.forEach((a) => {
      const { name } = a.getClip();

      /**
       * Tells the mixer to deactivate this action.
       * The action will be immediately stopped and completely reset.
       */
      if (this.isForward) {
        if (!namesToIgnore.some((i) => name.includes(i))) return;
        a.stop();
      } else {
        if (!namesToIgnore.some((i) => name.includes(i))) return;
        a.stop();
        a.time = a.getClip().duration;
      }
    });
  }

  /**
   * Play `current` actions.
   * If the time is negative (i.e. reversing), then reverse the actions.
   */
  playCurrentActionsInSequence() {
    this.status[this.currentActionIndex] = 0;
    const durations: number[] | undefined = this.isForward
      ? []
      : this.currentActionsInSequence?.map((a) => a.getClip().duration);
    const maxDuration: number = Math.max(...durations);
    // console.log('this.currentActionIndex :>> ', this.currentActionIndex);
    // console.log('this.max :>> ', this.max);
    if (this.currentActionIndex === this.max) this.handleLastSequence();
    this.currentActionsInSequence?.forEach((a) => {
      const { name } = a.getClip();
      this.status[this.currentActionIndex]++;
      const clipDuration: number = a.getClip().duration;

      if (this.isForward) {
        if (a.time === clipDuration) a.time = 0; // Re-reverse the time if at end (i.e play from start)
        a.paused = false;
        a.play();
      } else {
        /**
         * Reverse movable objects
         */
        if (name.includes('tele')) {
        } else {
        }
        // a.setDuration(maxDuration);
        // // a.time = maxDuration; // Reverse before playing (!)
        if (a.time === 0) a.time = clipDuration; // Reverse before playing (!)
        /**
         * The documentation states that `clampWhenFinished` is setting `.paused=true` (clampWhenFinished).
         * You need to therefore set it to `false` when you want to resume the animation instead of triggering `.play()` again.
         *
         * Furthermore you should reset the animation to be able to repeat it
         * and you should set the looping to LoopOnce to play the AnimationAction only once.
         */
        a.paused = false;
        a.setLoop(LoopOnce, Infinity);

        a.play();
        // a.play();
      }
    });
  }

  /**
   * The entire train needs to be reset before exiting.
   * N.B. We need to reset the **first** set of actions.
   */
  handleLastSequence() {
    const namesToInclude: string[] = [
      'kirow',
      'Kirow',
      'svangbas',
      'svanhbas',
      'lyftok',
    ];
    const currentActions = this._actionArray[0];
    currentActions.forEach((a) => {
      const { name } = a.getClip();
      console.log('Got', name);

      if (!namesToInclude.some((i) => name.includes(i))) {
        console.log('Stopping', name);
        a.stop();
      }
    });
  }

  /**
   * Determine the `previous` actions, relative to time.
   */
  updatePreviousActionsInSequence(): void {
    this.prevActionsInSequence = this.isForward
      ? this._actionArray[this.currentActionIndex - 1]
      : this._actionArray[this.currentActionIndex + 1];
  }

  /**
   * Handle `finished` event from the mixer.
   * The `this.status` object will keep track how many actions are running.
   *
   * N.B. The `this.status` does not know which actions are which.
   * It's only a counter (!).
   */
  finishedEvent(): void {
    this.status[this.currentActionIndex]--;
  }

  /**
   * Handles the visibility before the sequence has begun.
   */
  handleInitalVisibility(): void {
    this.gltf.children.forEach((c) => {
      c.visible = true;
      if (!this.visibility.pre.includes(c.name)) return;
      c.visible = false;
    });
  }

  /**
   * Check the visibility from the `ContentOverlord`.
   * Will force visibility for all objects and THEN hide the necessary.
   *
   * In essence, this method determines which objects shall be **hidden**.
   */
  handleVisibility(): void {
    const animation = this.animations[this.currentActionIndex];
    const { hidden } = animation;
    // TODO: Very slow approach!
    getAllChildren(this.gltf).forEach((c) => {
      c.visible = true;
      if (hidden === undefined) return;
      if (!hidden.includes(c.name)) return;
      c.visible = false;
    });
  }

  /**
   * Used to reset all actions to their original position.
   * Not used any more.
   *
   * The new `GLB` file has correct starting positions.
   * @deprecated No need to use this!
   */
  resetAllActions(): void {
    const names = [
      '4_mid1org_move2',
      '5_mid2_move3',
      '6_end2_move4',
      '3_End1_move',
    ];
    const actions: AnimationAction[] = [];
    this._actionArray.forEach((arr) => {
      arr.forEach((a) => {
        if (names.includes(a.getClip().name)) {
          a.time = a.getClip().duration;
          a.timeScale = -10;
          a.play();

          actions.push(a);
        }
      });
    });
  }

  handleEndpoint(): boolean {
    if (this.currentActionIndex < this.min) {
      LoggerMcLog.error('At beginning - cannot continue');
      this.alert(LOCALES.SEQUENCE_OVERLORD.ERROR_AT_START);
      return true;
    } else if (this.currentActionIndex > this.max) {
      LoggerMcLog.error('At end - cannot continue');
      this.alert(LOCALES.SEQUENCE_OVERLORD.ERROR_AT_END);
      return false;
    }
    return true;
  }

  /**
   * Check the `next` action. Determines if the sequence can be played.
   * If not, pause all actions but remain at the same index.
   * @param contentToFollow Reference to the current selected object
   * @returns Whether to continue whit the sequence
   */
  handleRestrictions(contentToFollow: Object3D): boolean {
    if (!this.handleEndpoint()) {
      return false;
    }
    const nextIndex = this.isForward // N.B. Already incremented
      ? this.currentActionIndex + 0
      : this.currentActionIndex - 0;
    // console.log('nextIndex :>> ', nextIndex);
    const nextAnimation = this.animations[nextIndex];

    const { restrictions } = nextAnimation;
    const isLocomotive = contentToFollow.name === '1Loket';
    // console.log('isLocomotive :>> ', isLocomotive);
    const restriction = isLocomotive
      ? restrictions.locomotive
      : restrictions.kirow;
    // console.log('restriction :>> ', restriction);
    return this.handleReason(restriction);
  }

  /**
   * Check the `current` action.
   *
   * There are two scenarios:
   *
   * 1. `isAtEnd`
   * 2. `regular`
   *
   * The first scenario occurs when the sequence is at the end (duh).
   * For example, if the locomotive has arrived at the station.
   * But, if the locomotive tries to continue - it cannot.
   *
   * The second scenario when a user reverses the time.
   * For example, if one tries to play a sequence backward.
   * @param contentToFollow Reference to the current selected object
   * @returns Whether to continue whit the sequence
   * @deprecated WIP (Might be redundant)
   */
  handleCurrentRestrictions(contentToFollow: Object3D): boolean {
    if (!this.hasInit) {
      if (!this.isForward) {
        LoggerMcLog.error('Cannot begin forward sequence');
        this.alert('Tåget måste åka framåt.');
        return false;
      }
    }
    // Don't return to -1
    // if (this.hasInit && this.currentActionIndex === -1) {
    //   LoggerMcLog.error('Cannot begin forward sequence');
    //   this.alert('Batteriet måste börja i tågets färdrikning.');
    //   this.currentActionIndex = -1; // Must be run first time
    //   this.status[this.currentActionIndex] = 1;
    //   this.hasInit = false;
    //   this.reset();
    //   return false;
    // }

    // Ignore index == -1
    // if (this.isAtEnd && this.currentActionIndex > 0) {
    //   return this.handleRestrictions(contentToFollow);
    // }

    // At the last sequence
    if (this.animations[this.currentActionIndex] === undefined) {
      LoggerMcLog.error('At end - cannot continue');
      this.alert(LOCALES.SEQUENCE_OVERLORD.APP_IS_DONE);
      return false;
    }
    const { restrictions } = this.animations[this.currentActionIndex];
    const isLocomotive = contentToFollow.name === '1Loket';

    const restriction = isLocomotive
      ? restrictions.locomotive
      : restrictions.kirow;
    console.log('restriction :>> ', restriction);
    const status = this.handleReason(restriction);
    // console.log('status :>> ', status);
    return status;
  }

  /**
   * Probably the worlds ugliest function in the world.
   */
  handleReason(restriction: {
    moveForward: boolean;
    moveBackward: boolean;
    reasonForward?: string;
    reasonBackward?: string;
  }): boolean {
    // If `forward`, check against move `forward` only
    if (this.isForward) {
      if (!restriction.moveForward) {
        LoggerMcLog.error('Cannot play forward sequence');
        this.alert(
          restriction.reasonForward || LOCALES.SEQUENCE_OVERLORD.GENERIC_ERROR,
        );
        return false;
      }
      // If `backward`, check against move `backward` only
    } else {
      if (!restriction.moveBackward) {
        LoggerMcLog.error('Cannot play backward sequence');
        this.alert(
          restriction.reasonBackward || LOCALES.SEQUENCE_OVERLORD.GENERIC_ERROR,
        );
        return false;
      }
    }
    // If either `forward` or `backward` is ok
    return true;
  }

  // eslint-disable-next-line
  alert(message): void {}

  reset = () => {
    this._actionArray.forEach((animations) => {
      animations.forEach((a) => {
        a.stop();
      });
    });
  };
}
