import ViewerOverlordBase from './base';
/**
 * Viewer based on `THREE`
 */
export default class ViewerOverlord extends ViewerOverlordBase {
  private static _instance: ViewerOverlord;

  /**
   * Singleton pattern.
   */
  public static getInstance(): ViewerOverlord {
    return this._instance;
  }

  /**
   * Even though ECMAScript includes syntax for class definitions,
   * ECMAScript objects are not fundamentally class-based such as those in C++, Smalltalk, or Java.
   *
   * Instead objects may be created in various ways including via a literal notation or
   * via constructors which create objects and then execute code that initializes all or
   * part of them by assigning initial values to their properties.
   *
   * Each constructor is a function that has a property named "prototype" that is used to implement prototype-based inheritance and shared properties.
   * Objects are created by using constructors in new expressions; for example,
   * - `new Date(2009,11)`
   * creates a new Date object.
   *
   * Invoking a constructor without using new has consequences that depend on the constructor.
   * For example, `Date()` produces a string representation of the current date and time rather than an object.
   *
   * Every object created by a constructor has an implicit reference (called the object’s prototype)
   * to the value of its constructor’s "prototype" property.
   *
   * Furthermore, a prototype may have a non-null implicit reference to its prototype, and so on;
   * this is called the prototype chain.
   *
   * When a reference is made to a property in an object,
   * that reference is to the property of that name in the first object in the prototype chain that contains a property of that name.
   * In other words:
   *
   * First the object mentioned directly is examined for such a property;
   * if that object contains the named property,
   * that is the property to which the reference refers;
   * if that object does not contain the named property,
   * the prototype for that object is examined next; and so on.
   */
  constructor() {
    // Force singleton pattern.
    if (ViewerOverlord._instance) return ViewerOverlord._instance;
    super();
    ViewerOverlord._instance = this;
  }

  public init(debug: boolean): void {
    this.debug = debug;
    this._init();
  }

  public setPlaybackSpeed(timeScale: number): void {
    if (this.mixer) this.mixer.timeScale = timeScale;
    this.sequenceOverlord.isForward = timeScale >= 0;
    const restrictionStatus = this.sequenceOverlord.handleCurrentRestrictions(
      this.contentToFollow,
    );

    if (!restrictionStatus) {
      this.mixer.timeScale = 0;
      this.setPlaybackSpeedEvent(0);
      return;
    }

    if (this.sequenceOverlord.hasInit === false) {
      this.sequenceOverlord.hasInit = true;
    }
    this.setPlaybackSpeedEvent(timeScale); // Update `antd`
    if (this.sequenceOverlord.isAtEnd) {
      this.sequenceOverlord.isAtEnd = false; // Must be `stoped` from here
      this.sequenceOverlord.handleVisibility();
      this.sequenceOverlord.updatePreviousActionsInSequence();
      this.sequenceOverlord.stopPreviousActionsInSequence();
      this.sequenceOverlord.updateCurrentActionsInSequence();
      this.sequenceOverlord.playCurrentActionsInSequence();
    }
  }
}
