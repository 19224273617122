import { Group } from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { MODEL_OBJ } from '../../../../../config/paths';
import { getAllChildren } from '../../../../../utils/get_children';
import ViewerOverlordBase from '../../../base';
/**
 * @deprecated
 */
export function dummyOBJLoadFunction(
  this: ViewerOverlordBase,
  materials: MTLLoader.MaterialCreator,
) {
  const onProgress = function (xhr) {
    if (xhr.lengthComputable) {
      const percentComplete = (xhr.loaded / xhr.total) * 100;
      const downloaded = Math.round(percentComplete);
      this.setPercent(downloaded);
    }
  };
  const load = function (object: Group) {
    object.rotateX(-Math.PI / 2);
    this.scene.add(object);
    this.animate();
    this.setPercent(null);
  };
  materials.preload();
  this.objLoader
    .setMaterials(materials)
    .load(MODEL_OBJ, load.bind(this), onProgress.bind(this));
}
/**
 * @deprecated
 */
export function dummyGLTFLoadFunction(this: ViewerOverlordBase, gltf: GLTF) {
  const { scene: parent } = gltf;
  const { viewerCoordinates } = this.positionOverlord;
  parent.matrixAutoUpdate = false;
  parent.castShadow = false;
  parent.receiveShadow = false;
  getAllChildren(gltf.scene).forEach((child) => {
    child.matrixAutoUpdate = false;
    child.castShadow = false;
    child.receiveShadow = false;
  });
  const scale = 2;
  parent.scale.set(scale, 1, scale);
  parent.position.set(
    viewerCoordinates.x + 70,
    viewerCoordinates.y + 55,
    viewerCoordinates.z - 250,
  ); // Arbitrary offset
  parent.updateMatrix();
  this.scene.add(parent);
  this.dumpContentMangager.photogrammetry = parent;
  this.setPercent(null);
}
const osmScale = 1;
const osmPosition = [-60, 0, -200];
/**
 * @deprecated
 */
export function dummyGLTFLoadGroundFunction(
  this: ViewerOverlordBase,
  gltf: GLTF,
) {
  const { scene: parent } = gltf;
  const { viewerCoordinates } = this.positionOverlord;
  parent.matrixAutoUpdate = false;
  getAllChildren(gltf.scene).forEach((child) => {
    child.matrixAutoUpdate = false;
  });
  parent.scale.set(osmScale, osmScale, osmScale);
  parent.position.set(
    viewerCoordinates.x + osmPosition[0],
    viewerCoordinates.y + osmPosition[1] + 0.1,
    viewerCoordinates.z + osmPosition[2],
  ); // Arbitrary offset
  parent.updateMatrix();
  this.scene.add(parent);
  this.dumpContentMangager.osm = parent;
  this.setPercent(null);
}

/**
 * @deprecated
 */
export function dummyGLTFLoadBackgroundFunction(
  this: ViewerOverlordBase,
  gltf: GLTF,
) {
  const { scene: parent } = gltf;
  const { viewerCoordinates } = this.positionOverlord;
  parent.matrixAutoUpdate = false;
  getAllChildren(gltf.scene).forEach((child) => {
    child.matrixAutoUpdate = false;
  });
  parent.scale.set(osmScale, osmScale, osmScale);
  parent.position.set(
    viewerCoordinates.x + osmPosition[0],
    viewerCoordinates.y + osmPosition[1],
    viewerCoordinates.z + osmPosition[2],
  ); // Arbitrary offset
  parent.updateMatrix();
  this.scene.add(parent);
  this.dumpContentMangager.buildings = parent;
  this.setPercent(null);
}
