export const ZOOM_IN: string = /* @__PURE__ */ 'Förstora bilden';
export const ZOOM_OUT: string = /* @__PURE__ */ 'Förminska bilden';
export const SWITCH_TO_3D: string = /* @__PURE__ */ 'Byt till 3D navigering';
export const SWITCH_TO_2D: string = /* @__PURE__ */ 'Byt till 2D navigering';
export const HOME: string = /* @__PURE__ */ 'Återställ bilden';
export const FOLLOW: string = /* @__PURE__ */ 'Följ';
export const FOLLOW_NOT: string = /* @__PURE__ */ 'Följ inte';
export const NOCLIP_ENABLE: string = /* @__PURE__ */ 'Låt kameran åka fritt';
export const NOCLIP_DISABLE: string =
  /* @__PURE__ */ 'Förhindra att kameran krockar med föremål';
export const RESET: string = /* @__PURE__ */ 'Stoppa';
export const RESET_MESSAGE: string = /* @__PURE__ */ 'Tåget har stoppats.';
export const ONLY_IN_3D: string =
  /* @__PURE__ */ 'Byt till 3D för att använda den här funktionen.';
