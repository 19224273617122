import { ANIMATION_STATES } from '../../../../../../config';
import KeyboardInput from '../../../../base/animation_controller/inputs/keyboard';
import State from '../../../../base/animation_controller/state';
/**
 * Idle State
 */
export default class IdleState extends State {
  constructor(parent) {
    super(parent);
    this.name = ANIMATION_STATES.IDLE;
  }

  enter(prevState: State) {
    const idleAction = this.parent.proxy.animations[this.name].action;
    if (prevState) {
      const prevAction = this.parent.proxy.animations[prevState.name].action;
      idleAction.time = 0.0;
      idleAction.enabled = true;
      idleAction.setEffectiveTimeScale(1.0);
      idleAction.setEffectiveWeight(1.0);
      idleAction.crossFadeFrom(prevAction, 0.5, true);
      idleAction.play();
    } else {
      idleAction.play();
    }
  }

  update(timeElapsed, input: KeyboardInput): void {
    if (input.keys.forward || input.keys.backward) {
      this.parent.setState(ANIMATION_STATES.WALK);
    } else if (input.keys.space) {
      this.parent.setState(ANIMATION_STATES.DANCE);
    }
  }
}
