import { LoopOnce } from 'three';
import { ANIMATION_STATES } from '../../../../../../config';
import State from '../../../../base/animation_controller/state';
/**
 * Idle State
 */
export default class DanceState extends State {
  finishedCallback: () => void;

  constructor(parent) {
    super(parent);
    this.name = ANIMATION_STATES.DANCE;

    this.finishedCallback = () => {
      this.finished();
    };
  }

  enter(prevState: State) {
    const curAction = this.parent.proxy.animations[this.name].action;
    if (!prevState) {
      curAction.play();
      return;
    }
    const prevAction = this.parent.proxy.animations[prevState.name].action;
    curAction.reset();
    curAction.setLoop(LoopOnce, 1);
    curAction.clampWhenFinished = true;
    curAction.crossFadeFrom(prevAction, 0.2, true);
    curAction.play();
  }

  finished() {
    this.cleanup();
    this.parent.setState(ANIMATION_STATES.IDLE);
  }

  cleanup() {
    const { action } = this.parent.proxy.animations[this.name];
    action.getMixer().removeEventListener('finished', this.finishedCallback);
  }

  exit() {
    this.cleanup();
  }
}
