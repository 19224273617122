import { Object3D } from 'three';

interface Objects {
  [key: string]: Object3D;
}
export default class VisibiltyOverlord {
  private objects: Objects;

  constructor() {
    this.objects = {};
  }

  add(id: string, object: Object3D): void {
    this.objects[id] = object;
  }

  setVisibility(id: string, visible: boolean): void {
    this.objects[id].visible = visible;
  }

  setVisibilityForAllObjects(visible: boolean): void {
    Object.values(this.objects).forEach((i) => {
      i.visible = visible;
    });
  }

  getObjects(): Objects {
    return this.objects;
  }
}
