import { Vector3 } from 'three';
/**
 * Helper for converting between `datum`, `projections` and coordinate system.
 * https://www.youtube.com/watch?v=kvO96NpXwsU
 */
export default class PositionOverlord {
  private THREE: Vector3;

  private WGS84: Vector3;

  constructor() {
    this.THREE = new Vector3();
    this.WGS84 = new Vector3();
  }

  get realLifeCoordinates(): Vector3 {
    return this.WGS84;
  }

  set realLifeCoordinates(coordinate: Vector3) {
    this.WGS84.copy(coordinate);
  }

  get viewerCoordinates(): Vector3 {
    return this.THREE;
  }

  set viewerCoordinates(coordinate: Vector3) {
    this.THREE.copy(coordinate);
  }
}
