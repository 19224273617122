import { DirectionalLight, Object3D } from 'three';
import ViewerOverlordBase from '../../base';
/**
 * Init global lights.
 * @deprecated
 */
export default function initLights(this: ViewerOverlordBase) {
  this.directionalLightTarget = new Object3D();
  this.directionalLight = new DirectionalLight(0xffffff, 1);
  // this.directionalLight.target = this.directionalLightTarget;
  // this.scene.add(this.directionalLight.target);
  this.directionalLight.color.setHSL(0.1, 1, 0.95);
  this.directionalLight.position.set(-50, 50, 100);

  this.scene.add(this.directionalLight);

  this.directionalLight.castShadow = true;

  this.directionalLight.shadow.mapSize.width = 2048;
  this.directionalLight.shadow.mapSize.height = 2048;

  const d = 200;

  this.directionalLight.shadow.camera.left = -d;
  this.directionalLight.shadow.camera.right = d;
  this.directionalLight.shadow.camera.top = d;
  this.directionalLight.shadow.camera.bottom = -d;

  this.directionalLight.shadow.camera.far = 3500;
  this.directionalLight.shadow.bias = -0.0001;

  // const directionalLightHelper = new DirectionalLightHelper(
  //   this.directionalLight,
  //   10,
  // );
  // this.scene.add(directionalLightHelper);
}
