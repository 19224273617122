import { EffectComposer, RenderPass } from 'postprocessing';
import ViewerOverlordBase from '../../base';

export default function initEffectComposer(this: ViewerOverlordBase) {
  this.effectComposer = new EffectComposer(this.renderer);
  // this.effectComposer.addPass(
  //   new RenderPass(this.skybox.scene, this.skybox.camera),
  // );
  this.effectComposer.addPass(new RenderPass(this.scene, this.camera));
}
