/* eslint-disable */
import {
  AdditiveAnimationBlendMode,
  AnimationAction,
  AnimationMixer,
  Group,
  LoopOnce,
  LoopPingPong,
  NormalAnimationBlendMode,
  Scene,
  Vector3,
} from 'three';
import { GLTF } from 'three/examples/jsm/loaders/GLTFLoader';
import contentOverlord from '../../../../../config/content_overlord';
import { AnimationSequence } from '../../../../../config/content_overlord/animation';
import getSequenceNumber from '../../../../../utils/get_sequence_number';
import LoggerMcLog from '../../../../../utils/logger_mc_log';
import ViewerOverlordBase from '../../../base';
import SequenceOverlord from '../../../base/sequence_overlord';

const POSITION = new Vector3();
const POSITIONS = [];

/**
 * @deprecated
 */
export default function updatePlayAllGUI(this: ViewerOverlordBase) {
  return;
  const { animationContent } = contentOverlord; // All animation content
  const contents = Object.values(animationContent); // As `array`
  const content = contents[contents.length - 1]; // Assume only one animation is active
  const { animations, visibility } = content;

  const playAllObject = {
    currentSequence: 'is: 0',
    playTo: animations.length - 1,
  };

  const sequenceOverlord = new SequenceOverlord(
    this.gltf,
    animations,
    visibility,
  );
  const actionDictionary: { [key: string]: AnimationAction[] } = {};
  this.clips.forEach((clip, clipIndex) => {
    if (+getSequenceNumber(clip.name) === NaN) return; // Some sequences are not a number
    const reference = actionDictionary[getSequenceNumber(clip.name)]; // Check if sequence exist
    if (reference === undefined) {
      actionDictionary[getSequenceNumber(clip.name)] = [];
    }
    const sequence: AnimationAction[] =
      actionDictionary[getSequenceNumber(clip.name)];
    // const additiveClip = AnimationUtils.makeClipAdditive(clip);
    const action: AnimationAction = this.mixer.clipAction(clip);

    action.loop = LoopOnce;
    // action.loop = LoopPingPong;
    action.clampWhenFinished = true; // Don't reset
    action.zeroSlopeAtEnd = false;
    action.zeroSlopeAtStart = false;
    // action.setEffectiveTimeScale(1); // Dunno what this does...
    sequence.push(action);
  });

  sequenceOverlord.actionDictionary = actionDictionary;

  const playAllFolder = this.gui.addFolder('All Sequences');

  // var currentActionIndex = 0;

  const contentToFollow = this.contentToFollow;

  const playNextAction = () => {
    // Will always trigger when a clip is finished
    sequenceOverlord.finishedEvent();
    // Check if any action is still running
    if (!sequenceOverlord.checkNumberOfActionsInSequence()) return;
    // // Check if `currentActionIndex` is in range.
    // if (sequenceOverlord.checkCurrentActionIndex()) return;
    // Debug `playTo`
    if (sequenceOverlord.currentActionIndex > playAllObject.playTo) {
      LoggerMcLog.warn(`Stopped! "PlayTo" = ${playAllObject.playTo}`);
      return;
    }
    // OK, increment !
    sequenceOverlord.increment();
    sequenceOverlord.handleVisibility();
    sequenceOverlord.updatePreviousActionsInSequence();
    sequenceOverlord.stopPreviousActionsInSequence();
    sequenceOverlord.updateCurrentActionsInSequence();
    sequenceOverlord.playCurrentActionsInSequence();
    LoggerMcLog.gandalf(
      animations[sequenceOverlord.currentActionIndex].comment,
    );
    playAllObject[
      'currentSequence'
    ] = `is: ${sequenceOverlord.currentActionIndex}`;
  };

  // Init
  sequenceOverlord.handleInitalVisibility();
  // sequenceOverlord.resetAllActions();

  const playAllFunction = () => {
    this.mixer.addEventListener('finished', playNextAction);
    sequenceOverlord.currentActionIndex = -1; // Must be run first time
    sequenceOverlord.status[sequenceOverlord.currentActionIndex] = 1; // Must be run first time
    playNextAction();
  };
  playAllObject['playAllFunction'] = playAllFunction;
  playAllObject['oneSeqForward'] = () => {
    LoggerMcLog.error('Not implemented');
  };
  playAllObject['oneSeqBack'] = () => {
    LoggerMcLog.error('Not implemented');
  };
  playAllFolder.add(playAllObject, 'playAllFunction');
  playAllFolder.add(playAllObject, 'playTo', 0, animations.length - 1, 1);
  playAllFolder
    .add(this.debugState, 'playbackSpeed', -4, 4, 0.1)
    .onChange((speed) => {
      if (this.mixer) this.mixer.timeScale = speed;
      // Check playback speed
      sequenceOverlord.isForward = speed >= 0;
      // LoggerMcLog.notImporant(`Is forward? :>> ${sequenceOverlord.isForward}`);
    });
  playAllFolder.add(playAllObject, 'oneSeqForward');
  playAllFolder.add(playAllObject, 'oneSeqBack');
  playAllFolder.add(playAllObject, 'currentSequence').listen();
  playAllFolder.add(this.debugState, 'followCrane');
}
