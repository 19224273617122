/**
 * Dummy `Keyboard` input.
 * Has hard coded key bindings.
 */
export default class KeyboardInput {
  public keys: {
    forward: boolean;
    backward: boolean;
    left: boolean;
    right: boolean;
    space: boolean;
    shift: boolean;
  };

  constructor() {
    this.keys = {
      forward: false,
      backward: false,
      left: false,
      right: false,
      space: false,
      shift: false,
    };
    document.addEventListener('keydown', (e) => this.onKeyDown(e), false);
    document.addEventListener('keyup', (e) => this.onKeyUp(e), false);
  }

  onKeyDown(event) {
    switch (event.code) {
      case 'KeyT': // w
        this.keys.forward = true;
        break;
      case 'KeyF': // a
        this.keys.left = true;
        break;
      case 'KeyG': // s
        this.keys.backward = true;
        break;
      case 'KeyH': // d
        this.keys.right = true;
        break;
      case 'Space': // SPACE
        this.keys.space = true;
        break;
      case 'ShiftLeft': // SHIFT
        this.keys.shift = true;
        break;
      default:
        break;
    }
  }

  onKeyUp(event) {
    switch (event.code) {
      case 'KeyT': // w
        this.keys.forward = false;
        break;
      case 'KeyF': // a
        this.keys.left = false;
        break;
      case 'KeyG': // s
        this.keys.backward = false;
        break;
      case 'KeyH': // d
        this.keys.right = false;
        break;
      case 'Space': // SPACE
        this.keys.space = false;
        break;
      case 'ShiftLeft': // SHIFT
        this.keys.shift = false;
        break;
      default:
        break;
    }
  }
}
