import ViewerOverlordBase from '../base';
/**
 * Animate a single frame.
 */
export default function animate(this: ViewerOverlordBase) {
  requestAnimationFrame(this.animate.bind(this));
  if (this.freeze) return; // TODO: Remove in production
  this.updateBeforeRender();
  this.render();
  this.updateAfterRender();
}
