import ViewerOverlordBase from '../../base';

export default function initAnimationDebugFolder(this: ViewerOverlordBase) {
  if (!this.debug) return;
  this.animFolder = null;
  this.animCtrls = [];
  this.clips = [];
  this.animFolder = this.gui.addFolder('Animation').close();
  this.animFolder.domElement.style.display = 'none';
  const playbackSpeedCtrl = this.animFolder.add(
    this.debugState,
    'playbackSpeed',
    0,
    1,
  );
  playbackSpeedCtrl.onChange((speed) => {
    if (this.mixer) this.mixer.timeScale = speed;
  });
  this.animFolder.add({ playAll: () => this.playAllClips() }, 'playAll');
  this.animFolder.add({ stopAll: () => this.stopAllClips() }, 'stopAll');
  this.animDictFolder = this.gui.addFolder('Grouped Animation').close();
  this.animDictFolder.domElement.style.display = 'none';
}
