import {
  BackSide,
  BoxGeometry,
  Mesh,
  MeshBasicMaterial,
  Object3D,
  PerspectiveCamera,
  Scene,
  TextureLoader,
} from 'three';
import { POSITIONS, PATHS } from '../../../../config';

export default class Skybox extends Object3D {
  parent: Object3D;

  camera: PerspectiveCamera;

  scene: Scene;

  constructor() {
    super();
    this.camera = new PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      1,
      POSITIONS.CAMERA_FAR,
    );
    const format = '.jpg';
    this.parent = new Object3D();
    this.scene = new Scene();

    const urls = [
      `${PATHS.SKYBOX}px${format}`,
      `${PATHS.SKYBOX}nx${format}`,
      `${PATHS.SKYBOX}py${format}`,
      `${PATHS.SKYBOX}ny${format}`,
      `${PATHS.SKYBOX}pz${format}`,
      `${PATHS.SKYBOX}nz${format}`,
    ];

    const materialArray = [];

    for (let i = 0; i < 6; i++) {
      const material = new MeshBasicMaterial({
        map: null,
        side: BackSide,
        depthTest: false,
        depthWrite: false,
        color: 0x424556,
      });

      materialArray.push(material);

      const loader = new TextureLoader();
      loader.load(
        urls[i],
        (texture) => {
          material.map = texture;
          material.needsUpdate = true;
          material.color.setHex(0xffffff);
        },
        // eslint-disable-next-line
        (xhr) => {
          // console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
        },
        // eslint-disable-next-line
        (xhr) => {
          // console.log('An error happened', xhr);
        },
      );
    }
    const skyGeometry = new BoxGeometry(
      POSITIONS.SKYBOX_SIZE,
      POSITIONS.SKYBOX_SIZE,
      POSITIONS.SKYBOX_SIZE,
    );
    const skybox = new Mesh(skyGeometry, materialArray);
    skybox.matrixAutoUpdate = false;
    this.scene.add(skybox);

    this.scene.traverse((n) => {
      n.frustumCulled = false;
    });
    this.parent.children.push(this.camera);
    this.camera.parent = this.parent;
  }

  update(camera: PerspectiveCamera) {
    this.camera.rotation.copy(camera.rotation);
    this.camera.fov = camera.fov;
    this.camera.aspect = camera.aspect;
    this.parent.rotation.x = 0;
    this.parent.updateMatrixWorld();
    this.camera.updateProjectionMatrix();
  }
}
