import { BlendFunction, EffectPass, OutlineEffect } from 'postprocessing';
import { getAllChildren } from '../../../../utils/get_children';
import ViewerOverlordBase from '../../base';

/**
 * Add an additional effect pass to the composer.
 * @deprecated [WIP]
 */
export default function updateOutline(this: ViewerOverlordBase) {
  this.outlineEffect = new OutlineEffect(this.scene, this.camera, {
    blendFunction: BlendFunction.ALPHA,
    edgeStrength: 10,
    pulseSpeed: 0.0,
    visibleEdgeColor: 0xffffff,
    hiddenEdgeColor: 0xffffff,
    height: 480,
    blur: false,
    xRay: true,
  });

  this.outlineEffect.selection.set(
    this.selectableObjects.flatMap((i) => getAllChildren(i)),
  );
  // include all children
  this.outlinePass = new EffectPass(this.camera, this.outlineEffect);
  this.effectComposer.addPass(this.outlinePass);

  this.selectedOutlineEffect = new OutlineEffect(this.scene, this.camera, {
    blendFunction: BlendFunction.ALPHA,
    edgeStrength: 10,
    pulseSpeed: 0.0,
    visibleEdgeColor: 0x00ff00,
    hiddenEdgeColor: 0x00ff000,
    height: 480,
    blur: false,
    xRay: true,
  });
  this.selectedOutlineEffect.selection.layer = 11;
  this.selectedOutlineEffect.selection.set(
    getAllChildren(this.contentToFollow),
  ); // include all children
  this.selectedOutlinePass = new EffectPass(
    this.camera,
    this.selectedOutlineEffect,
  );
  this.effectComposer.addPass(this.selectedOutlinePass);
}
