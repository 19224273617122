import ViewerOverlordBase from '../../base';

export default function resetCamera(this: ViewerOverlordBase) {
  this.controls.target.copy(this.positionOverlord.viewerCoordinates);
  this.camera.position.set(
    this.positionOverlord.viewerCoordinates.x,
    1_000,
    this.positionOverlord.viewerCoordinates.z,
  );
  this.camera.updateMatrix();
  this.controls.lockCamera = true;
  this.controls.update();
}
