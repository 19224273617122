export const RENDER_AREA: string =
  /* @__PURE__ */ '5bc4ec62-b9ae-4d5a-924e-f9c271866599';
export const CANVAS: string = /* @__PURE__ */ 'render-area';
export const STATS: string =
  /* @__PURE__ */ '89f0af2a-2ddd-44eb-85bf-1629d2d6a9b5';
export const PAGE_HEADER: string =
  /* @__PURE__ */ '4217c55c-a8d1-4fed-9d1a-5e9879f66668';
export const CONTROLS: string =
  /* @__PURE__ */ 'b12adc2d-7661-4295-86f7-0e71f21f63d5';
export const PLAYBACK_SPEED: string =
  /* @__PURE__ */ 'cbc56993-a740-4ab8-924c-20d27b4d022f';
export const SELECT: string =
  /* @__PURE__ */ 'fcd3e1ad-e593-4451-a855-44a06e78a385';
export const ALERT: string =
  /* @__PURE__ */ '5ae11617-2564-4df3-ba0c-413e08af9a35';
