import { AnimationClip, AnimationMixer } from 'three';
import ViewerOverlordBase from '../../base';

export default function setClips(
  this: ViewerOverlordBase,
  clips: AnimationClip[],
) {
  if (this.mixer) {
    this.mixer.stopAllAction();
    this.mixer.uncacheRoot(this.mixer.getRoot());
    this.mixer = null;
  }
  this.clips = clips;
  if (!clips.length) return;
  this.mixer = new AnimationMixer(this.content);
}
