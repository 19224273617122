import { IDENTIFIERS } from '../../../../config';
import ViewerOverlordBase from '../../base';

/**
 * @deprecated This didn't work that well in CST. Needs a check!
 */
export default function initResize(this: ViewerOverlordBase) {
  // window.onresize = onresize.bind(this);
  const THIS = this;
  const onresize = (dom_elem, callback) => {
    const resizeObserver = new ResizeObserver(() => callback());
    resizeObserver.observe(dom_elem);
  };
  const el = document.getElementById(IDENTIFIERS.RENDER_AREA);
  const callback = function () {
    THIS.shouldResize = true;
    setTimeout(() => {
      THIS.resize();
      THIS.shouldResize = false;
    }, 10);
  };
  onresize(el, callback);
}
