import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import ViewerOverlordBase from '../../../base';
/**
 *
 * @param this
 */
export default function initGLTFLoader(this: ViewerOverlordBase) {
  this.gltfLoader = new GLTFLoader(this.loadingManager);
  this.gltfLoader.setDRACOLoader(this.dracoLoader);
}
