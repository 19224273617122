import {
  Color,
  DoubleSide,
  Mesh,
  PlaneGeometry,
  RawShaderMaterial,
  Vector2,
} from 'three';

import vert from './shaders/vert';
import frag from './shaders/frag';

interface Params {
  geometry?: any;
  aspect?: number;
  grainScale?: number;
  colors?: string[];
}
/**
 *
 * @param params Options
 * @returns Background mesh
 */
export default function createBackground(params: Params) {
  // @ts-ignore
  // eslint-disable-next-line
  params = params || {};
  const geometry = params.geometry || new PlaneGeometry(2, 2, 1);
  const material = new RawShaderMaterial({
    vertexShader: vert,
    fragmentShader: frag,
    side: DoubleSide,
    uniforms: {
      // @ts-ignore
      aspectCorrection: { type: 'i', value: false },
      // @ts-ignore
      aspect: { type: 'f', value: 1 },
      // @ts-ignore
      grainScale: { type: 'f', value: 0.005 },
      // @ts-ignore
      grainTime: { type: 'f', value: 0 },
      // @ts-ignore
      noiseAlpha: { type: 'f', value: 0.25 },
      // @ts-ignore
      offset: { type: 'v2', value: new Vector2(0, 0) },
      // @ts-ignore
      scale: { type: 'v2', value: new Vector2(1, 1) },
      // @ts-ignore
      smooth: { type: 'v2', value: new Vector2(0.0, 1.0) },
      // @ts-ignore
      color1: { type: 'c', value: new Color('#fff') },
      // @ts-ignore
      color2: { type: 'c', value: new Color('#283844') },
    },
    depthTest: false,
  });
  const mesh = new Mesh(geometry, material);
  mesh.frustumCulled = false;

  function fromArray(array, VectorType) {
    if (Array.isArray(array)) {
      return new VectorType().fromArray(array);
    }
    return array;
  }
  // eslint-disable-next-line
  function style(params) {
    // eslint-disable-next-line
    params = params || {};
    if (Array.isArray(params.colors)) {
      // eslint-disable-next-line
      const colors = params.colors.map(function (c) {
        if (typeof c === 'string' || typeof c === 'number') {
          return new Color(c);
        }
        return c;
      });
      material.uniforms.color1.value.copy(colors[0]);
      material.uniforms.color2.value.copy(colors[1]);
    }
    if (typeof params.aspect === 'number') {
      material.uniforms.aspect.value = params.aspect;
    }
    if (typeof params.grainScale === 'number') {
      material.uniforms.grainScale.value = params.grainScale;
    }
    if (typeof params.grainTime === 'number') {
      material.uniforms.grainTime.value = params.grainTime;
    }
    if (params.smooth) {
      const smooth = fromArray(params.smooth, Vector2);
      material.uniforms.smooth.value.copy(smooth);
    }
    if (params.offset) {
      const offset = fromArray(params.offset, Vector2);
      material.uniforms.offset.value.copy(offset);
    }
    if (typeof params.noiseAlpha === 'number') {
      material.uniforms.noiseAlpha.value = params.noiseAlpha;
    }
    if (typeof params.scale !== 'undefined') {
      // eslint-disable-next-line
      let scale = params.scale;
      if (typeof scale === 'number') {
        scale = [scale, scale];
      }
      scale = fromArray(scale, Vector2);
      material.uniforms.scale.value.copy(scale);
    }
    if (typeof params.aspectCorrection !== 'undefined') {
      material.uniforms.aspectCorrection.value = Boolean(
        params.aspectCorrection,
      );
    }
  }

  // eslint-disable-next-line
  (mesh as any).style = style;
  // eslint-disable-next-line
  if (params) (mesh as any).style(params);
  return mesh;
}
