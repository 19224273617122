import { LinearEncoding, sRGBEncoding } from 'three';
import traverseMaterials from '../../../../utils/traverse_materials';
import ViewerOverlordBase from '../../base';

export default function updateTextureEncoding(this: ViewerOverlordBase) {
  const encoding =
    this.debugState.textureEncoding === 'sRGB' ? sRGBEncoding : LinearEncoding;
  traverseMaterials(this.content, (material) => {
    if (material.map) material.map.encoding = encoding;
    if (material.emissiveMap) material.emissiveMap.encoding = encoding;
    if (material.map || material.emissiveMap) material.needsUpdate = true;
  });
}
