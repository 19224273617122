/* eslint-disable */
const MESH_NAMES = {
  TRACKPATH1: 'Trackpath1',
  LIGHT: 'Light',
  CAMERA: 'Camera',
  TRACKPATH1001: 'Trackpath1001',
  '7KIROW_BOOGIE': '7kirow_boogie',
  KIROW_BOOGIE: 'kirow_boogie',
  KIROW_BOOGIE_1: 'kirow_boogie_1',
  KIROW_SVANGBAS: 'kirow_svangbas',
  KIROW_SVANGBAS_1: 'kirow_svangbas_1',
  KIROW_SVANGBAS_2: 'kirow_svangbas_2',
  KIROW_TELE_BAK3: 'kirow_tele_bak3',
  KIROW_TELE_BAK3_1: 'kirow_tele_bak3_1',
  KIROW_TELE_BAK3_2: 'kirow_tele_bak3_2',
  KIROW_TELE_BAK2: 'kirow_tele_bak2',
  KIROW_TELE_BAK2_1: 'kirow_tele_bak2_1',
  KIROW_TELE_BAK2_2: 'kirow_tele_bak2_2',
  KIROW_TELE_BAK1: 'kirow_tele_bak1',
  KIROW_TELE_BAK1_1: 'kirow_tele_bak1_1',
  KIROW_TELE_BAK1_2: 'kirow_tele_bak1_2',
  KIROW_TELE_FRAM3: 'kirow_tele_fram3',
  KIROW_TELE_FRAM3_1: 'kirow_tele_fram3_1',
  KIROW_TELE_FRAM3_2: 'kirow_tele_fram3_2',
  KIROW_TELE_FRAM3_3: 'kirow_tele_fram3_3',
  KIROW_TELE_FRAM2: 'kirow_tele_fram2',
  KIROW_TELE_FRAM2_1: 'kirow_tele_fram2_1',
  KIROW_TELE_FRAM2_2: 'kirow_tele_fram2_2',
  KIROW_TELE_FRAM1: 'kirow_tele_fram1',
  KIROW_TELE_FRAM1_1: 'kirow_tele_fram1_1',
  KIROW_TELE_FRAM1_2: 'kirow_tele_fram1_2',
  KIROW_LYFTOK: 'kirow_lyftok',
  KIROW_LYFTOK_1: 'kirow_lyftok_1',
  KIROW_LYFTOK_2: 'kirow_lyftok_2',
  '1LOKET': '1Loket',
  LOKET: 'Loket',
  LOKET_1: 'Loket_1',
  LOKET_2: 'Loket_2',
  LOKET_3: 'Loket_3',
  LOKET_4: 'Loket_4',
  LOKET_5: 'Loket_5',
  LOKET_6: 'Loket_6',
  LOKET_7: 'Loket_7',
  LOKET_8: 'Loket_8',
  LOKET_9: 'Loket_9',
  LOKET_10: 'Loket_10',
  LOKET_11: 'Loket_11',
  LOKET_12: 'Loket_12',
  LOKET_13: 'Loket_13',
  LOKET_14: 'Loket_14',
  LOKET_15: 'Loket_15',
  LOKET_16: 'Loket_16',
  LOKET_17: 'Loket_17',
  LOKET_18: 'Loket_18',
  LOKET_19: 'Loket_19',
  '2RALSVAGN_RAM': '2ralsvagn_ram',
  LUTDEL: 'lutdel',
  MID2_ONTRAIN: 'Mid2_ontrain',
  BAK002: 'BAK002',
  BAK002_1: 'BAK002_1',
  BAK002_2: 'BAK002_2',
  BOGGIE_1: 'boggie_1',
  BOGGIE_2: 'boggie_2',
  '4PLATTVAGN_SHORT': '4plattvagn_short',
  HJULAXEL: 'hjulaxel',
  HJULAXEL2: 'hjulaxel2',
  PLATTFORM: 'plattform',
  '5PLATTVAGN_LONG': '5plattvagn_long',
  BOGGIE_002: 'boggie_002',
  BOGGIE_1001: 'boggie_1001',
  END1_ONTRAIN: 'End1_ontrain',
  TAK_002002: 'Tak_002002',
  TAK_002002_1: 'Tak_002002_1',
  TAK_002002_2: 'Tak_002002_2',
  PLATTFORM001: 'plattform001',
  '3PLATTVAGN_LONG2': '3plattvagn_long2',
  BOGGIE_002001: 'boggie_002001',
  BOGGIE_1002: 'boggie_1002',
  END2_OMTRAIN: 'End2_omtrain',
  TAK_001002: 'Tak_001002',
  TAK_001002_1: 'Tak_001002_1',
  TAK_001002_2: 'Tak_001002_2',
  PLATTFORM002: 'plattform002',
  '6RALSVAGN_RAM2': '6ralsvagn_ram2',
  LUTDEL001: 'lutdel001',
  MID1_ONTRAIN: 'Mid1_ontrain',
  BAK001002: 'BAK001002',
  BAK001002_1: 'BAK001002_1',
  BAK001002_2: 'BAK001002_2',
  BOGGIE_1003: 'boggie_1003',
  BOGGIE_2001: 'boggie_2001',
  TESTLOOK: 'Testlook',
  LOKET001: 'Loket001',
  LOKET001_1: 'Loket001_1',
  LOKET001_2: 'Loket001_2',
  LOKET001_3: 'Loket001_3',
  LOKET001_4: 'Loket001_4',
  LOKET001_5: 'Loket001_5',
  LOKET001_6: 'Loket001_6',
  LOKET001_7: 'Loket001_7',
  LOKET001_8: 'Loket001_8',
  LOKET001_9: 'Loket001_9',
  LOKET001_10: 'Loket001_10',
  LOKET001_11: 'Loket001_11',
  LOKET001_12: 'Loket001_12',
  LOKET001_13: 'Loket001_13',
  LOKET001_14: 'Loket001_14',
  LOKET001_15: 'Loket001_15',
  LOKET001_16: 'Loket001_16',
  LOKET001_17: 'Loket001_17',
  LOKET001_18: 'Loket001_18',
  LOKET001_19: 'Loket001_19',
  CUBE: 'Cube',
  CUBE001: 'Cube001',
  CUBE002: 'Cube002',
  CUBE003: 'Cube003',
  CUBE004: 'Cube004',
  MID2_ORG: 'Mid2_org',
  BAK: 'BAK',
  BAK_1: 'BAK_1',
  BAK_2: 'BAK_2',
  MID1_ORG: 'Mid1_org',
  BAK001: 'BAK001',
  BAK001_1: 'BAK001_1',
  BAK001_2: 'BAK001_2',
  SLIPERS_EXTRA: 'Slipers_extra',
  END2_ORG: 'End2_org',
  TAK_001: 'Tak_001',
  TAK_001_1: 'Tak_001_1',
  TAK_001_2: 'Tak_001_2',
  END1_ORG: 'End1_org',
  TAK_002: 'Tak_002',
  TAK_002_1: 'Tak_002_1',
  TAK_002_2: 'Tak_002_2',
  END1_NEW: 'End1_new',
  TAK_002001: 'Tak_002001',
  TAK_002001_1: 'Tak_002001_1',
  TAK_002001_2: 'Tak_002001_2',
  END2_NEW: 'End2_new',
  TAK_001001: 'Tak_001001',
  TAK_001001_1: 'Tak_001001_1',
  TAK_001001_2: 'Tak_001001_2',
  MID1_NEW: 'Mid1_new',
  BAK001001: 'BAK001001',
  BAK001001_1: 'BAK001001_1',
  BAK001001_2: 'BAK001001_2',
  MID2_NEW: 'Mid2_new',
  BAK001_3: 'BAK001_3',
  BAK001_4: 'BAK001_4',
  BAK001_5: 'BAK001_5',
  PLANE: 'Plane',
  PLANE001: 'Plane001',
  FIXED: 'Fixed',
  DEL_01: 'Del_01',
  DEL_01_1: 'Del_01_1',
  DEL_01_2: 'Del_01_2',
  DEL_01_3: 'Del_01_3',
  DEL_01_4: 'Del_01_4',
  DEL_01_5: 'Del_01_5',
  DEL_01_6: 'Del_01_6',
  DEL_01_7: 'Del_01_7',
  DEL_01_8: 'Del_01_8',
  DEL_01_9: 'Del_01_9',
  DEL_01_10: 'Del_01_10',
  DEL_01_11: 'Del_01_11',
  DEL_01_12: 'Del_01_12',
  DEL_01_13: 'Del_01_13',
  DEL_01_14: 'Del_01_14',
  DEL_01_15: 'Del_01_15',
  DEL_01_16: 'Del_01_16',
  DEL_01_17: 'Del_01_17',
  DEL_01_18: 'Del_01_18',
  DEL_01_19: 'Del_01_19',
  DEL_01_20: 'Del_01_20',
  DEL_01_21: 'Del_01_21',
  DEL_01_22: 'Del_01_22',
  DEL_01_23: 'Del_01_23',
  DEL_01_24: 'Del_01_24',
  DEL_01_25: 'Del_01_25',
  DEL_01_26: 'Del_01_26',
  DEL_01_27: 'Del_01_27',
  DEL_01_28: 'Del_01_28',
  DEL_01_29: 'Del_01_29',
  DEL_01_30: 'Del_01_30',
};

const PARENT_MESH_NAMES = {
  LOKET: '1Loket',
  RALSVAGN_RAM: '2ralsvagn_ram',
  PLATTVAGN_LONG2: '3plattvagn_long2',
  PLATTVAGN_SHORT: '4plattvagn_short',
  PLATTVAGN_LONG: '5plattvagn_long',
  RALSVAGN_RAM2: '6ralsvagn_ram2',
  KIROW_BOOGIE: '7kirow_boogie',
  END1_NEW: 'End1_new',
  END1_ORG: 'End1_org',
  END2_NEW: 'End2_new',
  END2_ORG: 'End2_org',
  FIXED: 'Fixed',
  MID1_NEW: 'Mid1_new',
  MID1_ORG: 'Mid1_org',
  MID2_NEW: 'Mid2_new',
  MID2_ORG: 'Mid2_org',
  PLANE: 'Plane',
  PLANE001: 'Plane001',
  SLIPERS_EXTRA: 'Slipers_extra',
  TESTLOOK: 'Testlook',
  TRACKPATH1: 'Trackpath1',
  TRACKPATH1001: 'Trackpath1001',
};

const CLIP_NAMES = {
  '0': [
    '7kirow_arrival',
    '1lok_arrival',
    '2ralsvagn_arrival',
    '4plattvagn_attival',
    '5plattvagn_arrival',
    '3plattvagn_arrival',
    '6ralsvagn_arrival',
  ],
  '1': ['7kirow_transition'],
  '2': [
    'svangbas_move1',
    '7kirow_move1',
    'tele1_move1',
    'tele2_move1',
    'tele3_move1',
    'lyftok_move1',
    'telefram1_move1',
    'telefram2_move1',
    'telefram3_move1',
    'End1_move',
  ],
  '3': ['svanhbas_move2', 'kirow_move2', 'lyftok_move2', 'mid1org_move2'],
  '4': ['svangbas_move3', 'kirow_move3', 'lyftok_move3', 'mid2_move3'],
  '5': ['svangbas_move4', 'kirow_move4', 'lyftok_move4', 'end2_move4'],
  '6': ['svangbas_transition2', 'kirow_transition2', 'lyftok_transition2'],
  '7': [
    'svangbas_move5',
    'kirow_move5',
    'lyftok_move5',
    'telefram_move5',
    'end2_new_move5',
  ],
  '8': [
    'svangbas_move6',
    'kirow_move6',
    'lyftok_move6',
    'telefram_move6',
    'lutdel_move6',
    'midontrain_move6',
    'midnew_move6',
  ],
  '9': [
    'svangbas_move7',
    'Kirow_move7',
    'lyftok_move7',
    'telefram_move7',
    'lutdel_move7',
    'mid1_ontrain_move7',
    'mid1_new_move7',
  ],
  '10': [
    'svangbas_move8',
    'kirow_move8',
    'lyftok_move8',
    'telefram_move8',
    'end1_new_move8',
  ],
  '11': [
    'svangbas_transition3',
    'kirow_transition3',
    'tele1_transition3',
    'tele2_transition3',
    'tele3_transition3',
    'lyftok_transition3',
    'telefram1_transition3',
    'telefram2_transition3',
  ],
  '12': [
    'kirow_exit',
    '1lok_exit',
    '2ralsvagn_exit',
    '4plattvagn_exit',
    '5plattvagn_exit',
    '3plattvagn_exit',
    '6ralsvagn_exit',
  ],
  '13': [''],
  '14': ['ontrainAction'],
};

export { MESH_NAMES, PARENT_MESH_NAMES, CLIP_NAMES };
