import { UnsignedByteType } from 'three';
import ViewerOverlordBase from '../../base';
import { Environment } from '../../base/environment';
/**
 *
 * @param this
 * @param environment
 * @returns
 */
export default function getCubeMapTexture(
  this: ViewerOverlordBase,
  environment: Environment,
): Promise<any> {
  const { path } = environment;
  if (!path) return Promise.resolve({ envMap: null });
  return new Promise((resolve, reject) => {
    this.rgbeLoader.setDataType(UnsignedByteType).load(
      path,
      (texture) => {
        const envMap = this.pmremGenerator.fromEquirectangular(texture).texture;
        this.pmremGenerator.dispose();
        resolve({ envMap });
      },
      undefined,
      reject,
    );
  });
}
