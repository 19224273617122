/* eslint-disable */

const prettyStyles = [
  'background-image: url("https://media.giphy.com/media/2dQ3FMaMFccpi/giphy.gif")',
  'background-size: cover',
  'background-position: center',
  'color: #fff',
  'padding: 10px 20px',
  'line-height: 35px',
  'width : 70px',
  'height : 70px',
  'border : 5px solid black',
];

const gandalfStyles = [
  //   'background-image: url("https://th.bing.com/th/id/OIP.0kIJ9ulY05PMcof4l1QzKgHaDE?pid=ImgDet&rs=1")',
  'background-image: url("https://media.giphy.com/media/2dQ3FMaMFccpi/giphy.gif")',
  'background-size: cover',
  'background-position: center',
  'color: #fff',
  'padding: 10px 20px',
  'line-height: 35px',
  'width : 70px',
  'height : 70px',
  'border : 5px solid black',
];

const errorStyles = [
  'background-image: url("https://loginportal.funnyjunk.com/gifs/How+data+fixes+windows+blue+screen_9ba8ee_3235185.gif")',
  'background-size: cover',
  'background-position: center',
  'color: #fff',
  'padding: 10px 20px',
  'line-height: 35px',
  'width : 70px',
  'height : 70px',
  'border : 5px solid black',
];

const warnStyles = [
  'background-image: url("https://th.bing.com/th/id/R.d87915213ed1e3e5649651643fd1ed77?rik=%2bOIdR7k0Nt%2bdAQ&riu=http%3a%2f%2f25.media.tumblr.com%2ftumblr_lwvvzajXie1r33veco1_500.gif&ehk=0Tj%2f9Z0JPUOkWtexpq0yBMlxk1hdDXHaienlvVRYLoE%3d&risl=&pid=ImgRaw&r=0")',
  'background-size: cover',
  'background-position: center',
  'color: #fff',
  'padding: 10px 20px',
  'line-height: 35px',
  'width : 70px',
  'height : 70px',
  'border : 5px solid black',
];

const notImporantStyle = ['color: #000 !important'];

const LoggerMcLog = {
  pretty: (msg: string) => console.log(`%c${msg}`, prettyStyles.join(';')),
  gandalf: (msg: string) => console.log(`%c${msg}`, gandalfStyles.join(';')),
  error: (msg: string) => console.log(`%c${msg}`, errorStyles.join(';')),
  warn: (msg: string) => console.log(`%c${msg}`, warnStyles.join(';')),
  notImporant: (msg: string) =>
    console.log(`%c${msg}`, notImporantStyle.join(';')),
};

export default LoggerMcLog;
