import { DirectionalLight } from 'three';
import ViewerOverlordBase from '../../base';

/**
 * @deprecated
 */
export default function updateLights(this: ViewerOverlordBase) {
  return;
  // eslint-disable-next-line
  const state = this.debugState;
  // eslint-disable-next-line
  const lights = this.lights;

  if (state.addLights && !lights.length) {
    this.addLights();
  } else if (!state.addLights && lights.length) {
    this.removeLights();
  }

  this.renderer.toneMappingExposure = state.exposure;
  if (lights.length === 2) {
    (lights[0] as DirectionalLight).intensity = state.ambientIntensity;
    (lights[0] as DirectionalLight).color.setHex(state.ambientColor);
    (lights[1] as DirectionalLight).intensity = state.directIntensity;
    (lights[1] as DirectionalLight).color.setHex(state.directColor);
  }
}
