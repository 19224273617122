import { Clock, Vector3, Vector4 } from 'three';
import { OrbitControls } from './orbit';
import { KEY_BINDINGS } from '../../../../config';

interface FlyOrbitControlsEvent {
  type?: string;
  target?: any;
}
interface Event {
  type: string;
  target: any;
}
const changeEvent: FlyOrbitControlsEvent = /* @__PURE__ */ {
  type: 'fly-change',
};
const startEvent: FlyOrbitControlsEvent = /* @__PURE__ */ { type: 'fly-start' };
const endEvent: FlyOrbitControlsEvent = /* @__PURE__ */ { type: 'fly-end' };
const tempVector: Vector4 = /* @__PURE__ */ new Vector4(0, 0, 0, 0);
/**
 * `OrbitControls` with WSAD input.
 */
export default class FlyOrbitControls extends OrbitControls {
  enableFlight: boolean;

  baseSpeed: number;

  fastSpeed: number;

  forwardKey: string;

  backKey: string;

  leftKey: string;

  rightKey: string;

  upKey: string;

  downKey: string;

  fastKey: string;

  blurCallback: () => void;

  keyDownCallback: (e: any) => void;

  keyUpCallback: (e: any) => void;

  disableShiftKeyCallback: (e: any) => void;

  enableKeys: boolean;

  /**
   * `OrbitControls` with WSAD input.
   */
  constructor(camera, domElement) {
    // Disable use of shift key so we can use it for acceleration
    const disableShiftKeyCallback = (e) => {
      if (this.enabled) return;
      Object.defineProperty(e, 'shiftKey', {
        get() {
          return false;
        },
      });
    };

    domElement.addEventListener('pointerdown', disableShiftKeyCallback);

    super(camera, domElement);

    this.enableKeys = false;
    this.enableFlight = true;
    this.baseSpeed = 0.5;
    this.fastSpeed = 4;
    this.forwardKey = KEY_BINDINGS.FORWARD;
    this.backKey = KEY_BINDINGS.BACK;
    this.leftKey = KEY_BINDINGS.LEFT;
    this.rightKey = KEY_BINDINGS.RIGTH;
    this.upKey = KEY_BINDINGS.UP;
    this.downKey = KEY_BINDINGS.DOWN;
    this.fastKey = KEY_BINDINGS.FAST;

    let fastHeld: boolean = false;
    let forwardHeld: boolean = false;
    let backHeld: boolean = false;
    let leftHeld: boolean = false;
    let rightHeld: boolean = false;
    let upHeld: boolean = false;
    let downHeld: boolean = false;

    let originalDistance: number = 0;
    let originalMinDistance: number = 0;
    let originalMaxDistance: number = 0;
    let rafHandle: number = -1;
    const originalTarget = new Vector3();
    const clock = new Clock();

    const endFlight = () => {
      if (rafHandle !== -1) {
        // cancel the animation playing
        cancelAnimationFrame(rafHandle);
        rafHandle = -1;

        // store the original distances for the controls
        this.minDistance = originalMinDistance;
        this.maxDistance = originalMaxDistance;

        const targetDistance = Math.min(
          originalDistance,
          camera.position.distanceTo(originalTarget),
        );
        tempVector.set(0, 0, -1, 0).applyMatrix4(camera.matrixWorld);
        this.target
          .copy(camera.position)
          .addScaledVector(tempVector as unknown as Vector3, targetDistance);

        this.dispatchEvent(endEvent as Event);
      }
    };

    const updateFlight = () => {
      if (!this.enabled || !this.enableFlight) {
        return;
      }

      rafHandle = requestAnimationFrame(updateFlight);

      // get the direction
      tempVector.set(0, 0, 0, 0);
      if (forwardHeld) tempVector.z -= 1;
      if (backHeld) tempVector.z += 1;
      if (leftHeld) tempVector.x -= 1;
      if (rightHeld) tempVector.x += 1;
      if (upHeld) tempVector.y += 1;
      if (downHeld) tempVector.y -= 1;
      tempVector.applyMatrix4(camera.matrixWorld);

      // apply the movement
      const delta = 60 * clock.getDelta();
      const speed = fastHeld ? this.fastSpeed : this.baseSpeed;
      if (camera.position.y < this.minHeight) {
        tempVector.y = 0;
      }
      camera.position.addScaledVector(tempVector, speed * delta);
      this.target.addScaledVector(
        tempVector as unknown as Vector3,
        speed * delta,
      );

      this.dispatchEvent(changeEvent as Event);
    };

    const keyDownCallback = (e) => {
      const key = e.key.toLowerCase();

      if (rafHandle === -1) {
        originalMaxDistance = this.maxDistance;
        originalMinDistance = this.minDistance;
        originalDistance = camera.position.distanceTo(this.target);
        originalTarget.copy(this.target);
      }

      switch (key) {
        case this.forwardKey:
          forwardHeld = true;
          break;
        case this.backKey:
          backHeld = true;
          break;
        case this.leftKey:
          leftHeld = true;
          break;
        case this.rightKey:
          rightHeld = true;
          break;
        case this.upKey:
          upHeld = true;
          break;
        case this.downKey:
          downHeld = true;
          break;
        case this.fastKey:
          fastHeld = true;
          break;
        default:
          break;
      }

      switch (key) {
        case this.fastKey:
          break;
        case this.forwardKey:
          break;
        case this.backKey:
          break;
        case this.leftKey:
          break;
        case this.rightKey:
          break;
        case this.upKey:
          break;
        case this.downKey:
          e.stopPropagation();
          e.preventDefault();
          break;
        default:
          break;
      }

      if (
        forwardHeld ||
        backHeld ||
        leftHeld ||
        rightHeld ||
        upHeld ||
        downHeld ||
        fastHeld
      ) {
        this.minDistance = 0.01;
        this.maxDistance = 0.01;

        // Move the orbit target out to just in front of the camera
        tempVector.set(0, 0, -1, 0).applyMatrix4(camera.matrixWorld);
        this.target
          .copy(camera.position)
          .addScaledVector(tempVector as unknown as Vector3, 0.01);

        if (rafHandle === -1) {
          // start the flight and reset the clock
          this.dispatchEvent(startEvent as Event);
          clock.getDelta();
          updateFlight();
        }
      }
    };

    const keyUpCallback = (e) => {
      const key = e.key.toLowerCase();

      switch (key) {
        case this.fastKey:
        case this.forwardKey:
        case this.backKey:
        case this.leftKey:
        case this.rightKey:
        case this.upKey:
        case this.downKey:
          e.stopPropagation();
          e.preventDefault();
          break;
        default:
          break;
      }

      switch (key) {
        case this.forwardKey:
          forwardHeld = false;
          break;
        case this.backKey:
          backHeld = false;
          break;
        case this.leftKey:
          leftHeld = false;
          break;
        case this.rightKey:
          rightHeld = false;
          break;
        case this.upKey:
          upHeld = false;
          break;
        case this.downKey:
          downHeld = false;
          break;
        case this.fastKey:
          fastHeld = false;
          break;
        default:
          break;
      }

      if (
        !(
          forwardHeld ||
          backHeld ||
          leftHeld ||
          rightHeld ||
          upHeld ||
          downHeld ||
          fastHeld
        )
      ) {
        endFlight();
      }
    };

    const blurCallback = () => {
      endFlight();
    };
    this.blurCallback = blurCallback;
    this.keyDownCallback = keyDownCallback;
    this.keyUpCallback = keyUpCallback;
    this.disableShiftKeyCallback = disableShiftKeyCallback;
    window.addEventListener('blur', blurCallback);
    window.addEventListener('keydown', keyDownCallback);
    window.addEventListener('keyup', keyUpCallback);
  }

  dispose() {
    super.dispose();
    window.removeEventListener('blur', this.blurCallback);
    window.removeEventListener('keydown', this.keyDownCallback);
    window.removeEventListener('keyup', this.keyUpCallback);
    window.removeEventListener('pointerdown', this.disableShiftKeyCallback);
  }
}
