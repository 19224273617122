import contentOverlord from '../../../../config/content_overlord';
import { getAllChildren } from '../../../../utils/get_children';
import ViewerOverlordBase from '../../base';

export default function resetSequence(this: ViewerOverlordBase) {
  const { animationContent } = contentOverlord; // All animation content
  const contents = Object.values(animationContent); // As `array`
  const content = contents[0]; // From `contentOverlord`
  const { contentToFollowIndex } = content; // From `contentOverlord`
  this.mixer.removeEventListener('finished', this.playNextAction);
  this.sequenceOverlord.reset();
  this.contentToFollow = this.gltf.children[contentToFollowIndex];
  this.selectedOutlineEffect.selection.set(
    getAllChildren(this.contentToFollow),
  );
  this.setSelectedObject(this.contentToFollow.name);
  this.setPlaybackSpeedEvent(0);
  this.updateSequenceController();
}
