import { AnimationClip, Box3, Group, Vector3 } from 'three';
import ViewerOverlordBase from '../../base';

export default function setContent(
  this: ViewerOverlordBase,
  object: Group,
  clips: AnimationClip[],
) {
  // Helpers
  // eslint-disable-next-line
  const box = new Box3().setFromObject(object);
  // eslint-disable-next-line
  const size = box.getSize(new Vector3()).length();
  // eslint-disable-next-line
  const center = box.getCenter(new Vector3());

  this.scene.add(object);
  // @ts-ignore
  this.content = object;
  this.debugState.addLights = true;
  // eslint-disable-next-line
  this.content.traverse((node: any) => {
    if (node.isLight) {
      this.debugState.addLights = false;
    } else if (node.isMesh) {
      // TODO(https://github.com/mrdoob/three.js/pull/18235): Clean up.
      node.material.depthWrite = !node.material.transparent;
    }
  });
  this.setClips(clips);
  this.updateLights();
  this.updateEnvironment();
  this.updateTextureEncoding();
  this.updateOutline();
  this.updateSequenceController();
  // eslint-disable-next-line
  const scope = this;

  scope.setHasInit(true);

  if (!this.debug) {
    return;
  }
  this.updateGUI();
}
