import { POSITIONS } from '../../../../config';
import ViewerOverlordBase from '../../base';
import FlyOrbitControls from '../../base/controls';

export default function initControls(this: ViewerOverlordBase) {
  this.controls = new FlyOrbitControls(this.camera, this.renderer.domElement);
  this.controls.target.copy(POSITIONS.CONTROLS_TARGET);
  // Start with `noclip`
  this.controls.maxPolarAngle = Math.PI; // (0.9 * Math.PI) / 2;
  this.controls.minHeight = -9_000; // -POSITIONS.GROUND_HEIGHT_POSITION + 0.1;
  this.controls.maxDistance = POSITIONS.CONTROLS_MAX_DISTANCE;
  this.controls.update();
  this.lockCamera = true; // See OrbitController
}

export { FlyOrbitControls };
