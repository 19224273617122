import { Mesh, MeshBasicMaterial } from 'three';
import contentOverlord from '../../../../config/content_overlord';
import { getAllChildren } from '../../../../utils/get_children';
import ViewerOverlordBase from '../../base';

/**
 * @deprecated Load dummy data
 */
export default async function loadDummyData(this: ViewerOverlordBase) {
  const { staticContent } = contentOverlord; // All static content
  const contents = Object.values(staticContent); // As `array`
  /**
   * Await all loading. Not checking if the file/path is wrong.
   */
  const gltfs = await Promise.all(
    contents.map((i) => this.gltfLoader.loadAsync(i.path)),
  );

  for (let i = 0; i < contents.length; i++) {
    const content = contents[i]; // From `contentOverlord`
    const gltf = gltfs[i]; // Result from `GLTFLoader`
    const { scene: parent } = gltf;
    const { viewerCoordinates } = this.positionOverlord; // This is set inside `initMap`
    parent.userData.id = content.id;
    parent.userData.name = content.name;
    parent.matrixAutoUpdate = false; // Is static (!)
    parent.castShadow = content.castShadow;
    parent.receiveShadow = content.receiveShadow;

    /**
     * Apply the same rules to children.
     */
    getAllChildren(gltf.scene).forEach((child) => {
      if (content.reflectivity === 0) {
        if (child.type === 'Mesh') {
          const material: MeshBasicMaterial = (child as Mesh)
            .material as MeshBasicMaterial;
          ((child as Mesh).material as MeshBasicMaterial) =
            new MeshBasicMaterial();
          MeshBasicMaterial.prototype.copy.call(
            (child as Mesh).material as MeshBasicMaterial,
            material,
          );
        }
      }
      child.matrixAutoUpdate = false;
      child.castShadow = content.castShadow;
      child.receiveShadow = content.receiveShadow;
    });
    // Use `content.position` as an offset. TODO: Fix!
    parent.position.set(
      viewerCoordinates.x + content.position.x,
      viewerCoordinates.y + content.position.y,
      viewerCoordinates.z + content.position.z,
    );
    // Rotation not working, should use Euler instead. TODO: Fix!
    // parent.rotation.set(content.rotation)
    parent.scale.copy(content.scale);
    parent.updateMatrix(); // Update the transformation matrix one "last" time.
    this.scene.add(parent);
    this.visibilityOverlord.add(content.id, parent);
    if (content.isRelatedToAnimation) {
      this.animationBaseModel = parent;
    }
  }
}
