import ViewerOverlordBase from '../../base';
import createBackground from '../../base/vignette';

export default function initVignette(this: ViewerOverlordBase) {
  this.vignette = createBackground({
    aspect: this.camera.aspect,
    grainScale: 0.001,
    colors: [this.debugState.bgColor1, this.debugState.bgColor2],
  });
}
