import AIInput from '../inputs/ai';
import KeyboardInput from '../inputs/keyboard';
import ReactInput from '../inputs/react';
import AnimationControllerProxy from '../proxy';
import State from '../state';
/**
 * A `Finite State Machine` is a model of computation based on a hypothetical machine made of one or more states.
 * Only one single state of this machine can be active at the same time.
 * It means the machine has to transition from one state to another in to perform different actions.
 *
 * Caveats:
 * - Fixed set of states that the machine can be in
 * - The machine can only be in one state at a time
 * - A sequence of inputs is sent to the machine
 * - Every state has a set of transitions
 * - Every transition is associated with an input and pointing to a state
 *
 * Example:
 * A traffic Light has the following states and transitions:
 * - states: `Red`, `Yellow`, `Green`
 * - transitions: After a given time, `Red` will change to `Green`, `Green` to `Yellow`, and `Yellow` to `Red`
 */
export default class FiniteStateMachine {
  public proxy: AnimationControllerProxy;

  private states: { [key: string]: typeof State };

  public currentState: State;

  constructor() {
    this.states = {};
    this.currentState = null;
  }

  /**
   * Add `typeof State` to the `states` dictionary.
   *
   * N.B. The states are initialized as:
   * ```js
   * new this.states[name](this)
   * ```
   *
   * @param name Name of state
   * @param type Typeof state that will be initialized
   */
  public addState(name: string, type: typeof State): void {
    this.states[name] = type;
  }

  /**
   * - Signal `exit `to the old state.
   * - Set new/current state
   * - Signal `enter` to new state
   * @param name
   */
  public setState(name): void {
    const prevState = this.currentState;

    if (prevState) {
      if (prevState.name === name) return;
      prevState.exit();
    }

    const state: State = new this.states[name](this);
    this.currentState = state;
    state.enter(prevState);
  }

  /**
   * Update `current` state.
   * @param timeElapsed `THREE.Clock.getDelta()`
   * @param input
   * @returns
   */
  public update(
    timeElapsed: number,
    input: KeyboardInput | AIInput | ReactInput,
  ): void {
    if (!this.currentState) return;
    this.currentState.update(timeElapsed, input);
  }
}
