import {
  AnimationClip,
  AnimationMixer,
  Clock,
  CubeTextureLoader,
  DirectionalLight,
  Group,
  LoadingManager,
  Mesh,
  Object3D,
  PerspectiveCamera,
  PMREMGenerator,
  Raycaster,
  Scene,
  Vector2,
  WebGLRenderer,
} from 'three';
import { EffectComposer, EffectPass, OutlineEffect } from 'postprocessing';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader';
import { MTLLoader } from 'three/examples/jsm/loaders/MTLLoader';
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader';
import { TilesRenderer } from '3d-tiles-renderer';
import Stats from 'stats.js';
import GUI from 'lil-gui';
import { MapView } from 'geo-three';
import animate from '../animation_loop';
import render from '../animation_loop/render';
import {
  updateAfterRender,
  updateBeforeRender,
} from '../animation_loop/update';
import init from '../init';
import initAnimationMixer from '../init/animation_mixer';
import initCamera from '../init/camera';
import initControls, { FlyOrbitControls } from '../init/controls';
import initDebug from '../init/debug';
import loadDummyData from '../init/dummy_data';
import {
  dummyGLTFLoadBackgroundFunction,
  dummyGLTFLoadFunction,
  dummyGLTFLoadGroundFunction,
  dummyOBJLoadFunction,
} from '../init/dummy_data/dummy_function';
import initLights from '../init/lights';

import initLoadingManager from '../init/loaders/loader_manager';
import initMTLLoader from '../init/loaders/mtl';
import initOBJLoader from '../init/loaders/obj';
import initRenderer from '../init/renderer';
import initRenderArea from '../init/render_area';
import initResize from '../init/resize';
import initScene from '../init/scene';
import initSceneBackground from '../init/scene_background';
import initSkybox from '../init/skybox';
import initDracoLoader from '../init/loaders/draco';
import initGLTFLoader from '../init/loaders/gltf';
import AnimationController from '../init/animation_controller/controller';
import initFBXLoader from '../init/loaders/fbx';
import initAnimationController from '../init/animation_controller';
import Skybox from './skybox';
import initTilesRenderer from '../init/tiles_renderer';
import initMap from '../init/map';
import PositionOverlord from './position_overlord';
import resize from './resize';
import updateCamera from '../events/update_camera';
import zoomCamera from '../events/zoom_camera';
import resetCamera from '../events/reset_camera';
import initAnimationTest, { AnimationTest } from '../init/animaiton_test';
import VisibiltyOverlord from './visibility_overlord';
import initVisibilityOverlord from '../init/visibility_overlord';
import initPMREMGenerator from '../init/pmrem_generator';
import initVignette from '../init/vignette';
import getCubeMapTexture from '../events/get_cube_map_texture';
import addLights from '../events/add_lights';
import updateLights from '../events/update_lights';
import removeLights from '../events/remove_lights';
import updateEnvironment from '../events/update_environment';
import initContent from '../init/content';
import setClips from '../events/set_clips';
import initRGBELoader from '../init/loaders/rgbe';
import setContent from '../events/set_content';
import playAllClips from '../events/play_all_clips';
import updateGUI from '../events/update_gui';
import updateTextureEncoding from '../events/update_texture_encoding';
import initAnimationDebugFolder from '../init/animation_debug_folder';
import stopAllClips from '../events/stop_all_clips';
import updateSimpleGUI from '../events/update_gui/simple';
import updateGroupedGUI from '../events/update_gui/grouped';
import updatePlayAllGUI from '../events/update_gui/all';
import initEffectComposer from '../init/effect_composer';
import updateOutline from '../events/update_outline';
import initCubeTextureLoader from '../init/loaders/cube_texture_loader';
import updateSequenceController from '../events/update_sequence_controller';
import initRaycaster from '../init/raycaster';
import onMouseMove from '../events/on_mouse_move';
import onMouseDown from '../events/on_mouse_down';
import SequenceOverlord from './sequence_overlord';
import updateNoclip from '../events/update_noclip';
import updateFollowContent from '../events/update_follow_content';
import resetSequence from '../events/reset_sequence';

/**
 * Base
 */
export default class ViewerOverlordBase {
  protected initRenderArea = initRenderArea;

  protected renderArea: HTMLElement;

  protected initRenderer = initRenderer;

  protected renderer: WebGLRenderer;

  protected initDebug = initDebug;

  protected initScene = initScene;

  protected scene: Scene;

  protected initSceneBackground = initSceneBackground;

  protected initSkybox = initSkybox;

  protected initCamera = initCamera;

  protected camera: PerspectiveCamera;

  protected initLights = initLights;

  protected initControls = initControls;

  protected controls: FlyOrbitControls;

  protected initLoadingManager = initLoadingManager;

  protected initDracoLoader = initDracoLoader;

  protected dracoLoader: DRACOLoader;

  protected initGLTFLoader = initGLTFLoader;

  protected gltfLoader: GLTFLoader;

  protected initOBJLoader = initOBJLoader;

  protected objLoader: OBJLoader;

  protected initMTLLoader = initMTLLoader;

  protected mtlLoader: MTLLoader;

  protected initFBXLoader = initFBXLoader;

  protected fbxLoader: FBXLoader;

  protected initRGBELoader = initRGBELoader;

  protected rgbeLoader: RGBELoader;

  protected initResize = initResize;

  protected initAnimationController = initAnimationController;

  protected animationController: AnimationController;

  protected initAnimationMixer = initAnimationMixer;

  protected initAnimationDebugFolder = initAnimationDebugFolder;

  protected mixer: AnimationMixer;

  protected clock: Clock;

  protected loadDummyData = loadDummyData;

  protected dummyOBJLoadFunction = dummyOBJLoadFunction;

  protected dummyGLTFLoadFunction = dummyGLTFLoadFunction;

  protected dummyGLTFLoadGroundFunction = dummyGLTFLoadGroundFunction;

  protected dummyGLTFLoadBackgroundFunction = dummyGLTFLoadBackgroundFunction;

  protected updateBeforeRender = updateBeforeRender;

  protected updateAfterRender = updateAfterRender;

  protected render = render;

  protected animate = animate;

  protected _init = init;

  protected loadingManager: LoadingManager;

  protected stats: Stats | any;

  protected skybox: Skybox;

  public dumpContentMangager: { [key: string]: Object3D };

  protected initTilesRenderer = initTilesRenderer;

  protected tilesRenderer: TilesRenderer;

  public debug: boolean;

  protected gui: GUI;

  protected map: MapView;

  protected initMap = initMap;

  protected initContent = initContent;

  protected positionOverlord: PositionOverlord;

  public shouldResize: boolean;

  protected freeze: boolean;

  public initAnimationTest = initAnimationTest;

  protected animationTest: AnimationTest;

  protected initVisibilityOverlord = initVisibilityOverlord;

  public visibilityOverlord: VisibiltyOverlord;

  protected debugState: {
    followCrane: boolean;
    environment: string;
    background: boolean;
    playbackSpeed: number;
    actionStates: {};
    sequenceStates: {};
    wireframe: boolean;
    skeleton: boolean;
    grid: boolean;
    addLights: boolean;
    exposure: number;
    textureEncoding: string;
    ambientIntensity: number;
    ambientColor: number;
    directIntensity: number;
    directColor: number;
    bgColor1: string;
    bgColor2: string;
  };

  protected pmremGenerator: PMREMGenerator;

  protected initPMREMGenerator = initPMREMGenerator;

  protected initVignette = initVignette;

  protected vignette: any;

  protected removeLights = removeLights;

  protected updateLights = updateLights;

  protected addLights = addLights;

  protected updateEnvironment = updateEnvironment;

  protected setClips = setClips;

  protected updateGUI = updateGUI;

  protected updateSimpleGUI = updateSimpleGUI;

  protected updateGroupedGUI = updateGroupedGUI;

  protected updatePlayAllGUI = updatePlayAllGUI;

  protected lights: Object3D[];

  protected content: Mesh;

  protected clips: AnimationClip[];

  protected animCtrls: any;

  protected animFolder: any;

  protected animDictFolder: any;

  protected contentToFollow: Object3D;

  protected initEffectComposer = initEffectComposer;

  protected effectComposer: EffectComposer;

  protected gltf: Group;

  protected initCubeTextureLoader = initCubeTextureLoader;

  protected cubeTextureLoader: CubeTextureLoader;

  protected initRaycaster = initRaycaster;

  protected raycaster: Raycaster;

  protected raycasterObjects: Group;

  protected onMouseMove = onMouseMove;

  protected onMouseDown = onMouseDown;

  protected mouse: Vector2;

  protected selectedOutlineEffect: OutlineEffect;

  protected selectedOutlinePass: EffectPass;

  protected outlineEffect: OutlineEffect;

  protected outlinePass: EffectPass;

  protected hoveredObject: Object3D;

  selectedObject: Object3D;

  selectableObjects: Object3D[];

  lockCamera: boolean;

  directionalLight: DirectionalLight;

  directionalLightTarget: Object3D;

  animationBaseModel: Group;

  playNextAction: () => void;

  // eslint-disable-next-line
  setPercent(input: any): any {}

  // eslint-disable-next-line
  setSelectedObject(input: any): any {}

  // eslint-disable-next-line
  setHasInit(input: any): any {}

  // eslint-disable-next-line
  setPlaybackSpeedEvent(input: any): any {}

  public resize = resize;

  public updateCamera = updateCamera;

  public updateFollowContent = updateFollowContent;

  public updateNoclip = updateNoclip;

  public zoomCamera = zoomCamera;

  public resetCamera = resetCamera;

  protected getCubeMapTexture = getCubeMapTexture;

  protected setContent = setContent;

  protected playAllClips = playAllClips;

  protected stopAllClips = stopAllClips;

  protected updateTextureEncoding = updateTextureEncoding;

  protected updateOutline = updateOutline;

  protected updateSequenceController = updateSequenceController;

  protected sequenceOverlord: SequenceOverlord;

  public resetSequence = resetSequence;

  constructor() {
    this.dumpContentMangager = {};
  }
}
