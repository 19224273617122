import ViewerOverlordBase from '../base';
/**
 * Init the viewer.
 * N.B: The order is very important.
 */
export default async function init(this: ViewerOverlordBase) {
  this.initRenderArea(); // Always first
  this.initRenderer();
  this.initContent();
  this.initPMREMGenerator();
  this.initDebug();
  this.initAnimationDebugFolder();
  this.initVisibilityOverlord();
  this.initScene();
  this.initCamera();
  this.initControls();
  this.initMap(); // Init `camera`, `scene`, and `controls` first
  this.initSceneBackground();
  this.initLights();
  this.initLoadingManager();
  this.initCubeTextureLoader(); // First `Draco`, then `GLTF`
  this.initDracoLoader(); // First `Draco`, then `GLTF`
  this.initGLTFLoader();
  this.initOBJLoader();
  this.initMTLLoader();
  this.initFBXLoader();
  this.initRGBELoader();
  this.initRaycaster();
  this.initResize();
  this.initAnimationMixer();
  this.initEffectComposer();
  await this.loadDummyData();
  await this.initAnimationTest();
  this.setPercent(null);
  this.animate();
}
