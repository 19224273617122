import ViewerOverlordBase from '../../base';
import AnimationController from './controller';
/**
 * A `Finite State Machine` is a model of computation based on a hypothetical machine made of one or more states.
 * Only one single state of this machine can be active at the same time.
 * It means the machine has to transition from one state to another in to perform different actions.
 *
 * Caveats:
 * - Fixed set of states that the machine can be in
 * - The machine can only be in one state at a time
 * - A sequence of inputs is sent to the machine
 * - Every state has a set of transitions
 * - Every transition is associated with an input and pointing to a state
 *
 * Example:
 * A traffic Light has the following states and transitions:
 * - states: `Red`, `Yellow`, `Green`
 * - transitions: After a given time, `Red` will change to `Green`, `Green` to `Yellow`, and `Yellow` to `Red`
 */
export default function initAnimationController(this: ViewerOverlordBase) {
  this.animationController = new AnimationController({
    scene: this.scene,
    camera: this.camera,
    loader: this.fbxLoader,
    modelPosition: this.positionOverlord.viewerCoordinates,
  });
}
