import { POSITIONS } from '../../../../config';
import ViewerOverlordBase from '../../base';

export default function updateNoclip(
  this: ViewerOverlordBase,
  noclip: boolean,
) {
  this.controls.minHeight = noclip
    ? -9_000
    : -POSITIONS.GROUND_HEIGHT_POSITION + 0.1;
  this.controls.maxPolarAngle = noclip ? Math.PI : (0.9 * Math.PI) / 2;
}
