import { PATHS } from '../../../../config';

export interface Environment {
  id: string;
  name: string;
  path: string;
  format: string;
}
const environments: Environment[] = /* @__PURE__ */ [
  {
    id: '',
    name: 'None',
    path: PATHS.ENVIRONMENT.NONE,
    format: '.hdr',
  },
  {
    id: 'flen',
    name: 'Flen',
    path: PATHS.ENVIRONMENT.FLEN,
    format: '.hdr',
  },
  {
    id: 'venice-sunset',
    name: 'Venice Sunset',
    path: PATHS.ENVIRONMENT.SUNSET,
    format: '.hdr',
  },
  {
    id: 'footprint-court',
    name: 'Footprint Court (HDR Labs)',
    path: PATHS.ENVIRONMENT.FOOTPRINT,
    format: '.hdr',
  },
];

export default environments;
